import {
    SET_CHAT_THREADS
} from '../actions/chat';

const initialState = {
    chatThreads: null
}

export default (state = initialState, action) => {

    switch (action.type) {
        case SET_CHAT_THREADS:
            return {
                ...state,
                chatThreads: action.chatThreads,
            };
        default:
            return state;
    }

}