import React from 'react';

import DashboardWrapper from '../../components/dashboard/DashboardWrapper';
import Chat from '../../components/chat/Chat';

const CfoChat = () => {

    return (
        <DashboardWrapper
            screen={"cfo"}
            customMainContainerStyle={{ paddingTop: "0px", paddingLeft: "0px", paddingRight: "0px", maxWidth: "100%" }}>

            <Chat page={"cfo"} />

        </DashboardWrapper>
    )

}

export default CfoChat;