import React, { useContext } from 'react';

import CustomModal from './CustomModal';
import { ThemeContext } from '../../context/theme';

const ErrorModal = ({ onClose, errorMessage }) => {

    const [{ theme }, _] = useContext(ThemeContext);

    return (
        <CustomModal closeModal={onClose} title={"Error"}>
            <div className="error-modal-container">
                <p className={`regular-font-${theme.theme}`}>
                    {errorMessage}
                </p>
            </div>
        </CustomModal>
    )

}

export default ErrorModal;