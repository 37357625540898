import React from 'react';

import "./ChatWelcomeMessage.css";

const ChatWelcomeMessage = ({ logo, message }) => {

    return (
        <div className="chat-welcome-message-container">
            <img className="chat-welcome-message-logo" src={logo} />
            <p className={`chat-welcome-message`}>{message}</p>
        </div>
    )

}

export default ChatWelcomeMessage;