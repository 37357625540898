import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconContext } from 'react-icons';
import { MdAccountCircle } from "react-icons/md";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaChartLine, FaMoneyBillWave, FaBoxOpen } from "react-icons/fa";
import { GiBrain } from "react-icons/gi";
import { IoPricetag } from "react-icons/io5";
import { BsPersonHeart } from "react-icons/bs";

import DashboardWrapper from '../../components/dashboard/DashboardWrapper';

import "./Dashboard.css";

const Dashboard = () => {

    const navigate = useNavigate();

    const icons = [
        { title: "Strategic", icon: <GiBrain /> },
        { title: "Financial", icon: <FaMoneyBillWave /> },
        { title: "Product", icon: <FaBoxOpen /> },
        { title: "Account based", icon: <MdAccountCircle /> },
        { title: "Customer cohort", icon: <FaPeopleGroup /> },
        { title: "Performance", icon: <FaChartLine /> },
        { title: "Brand", icon: <IoPricetag /> },
        { title: "Employee-led", icon: <BsPersonHeart /> },
    ]

    return (
        <DashboardWrapper screen={"dashboard"}
            customMainContainerStyle={{ paddingTop: "0px", paddingLeft: "60px", paddingRight: "60px" }}>

            <div className="dashboard-container">

                <div className="dashboard-logo-row">
                    {icons.map((icon, index) =>
                        <IconContext.Provider
                            value={{ className: "homepage-icon" }}>
                            <div key={index}>
                                {icon.icon}
                            </div>
                        </IconContext.Provider>
                    )}
                </div>

                <p className={"dashboard-font"}>
                    Articles on marketing accountability can be divided into 8 categories. Two of these categories talking about the reasons WHY to do marketing (to win in the marketplace; and to generate superior financial performance) while six talk about HOW to do marketing.
                </p>
                <p className={"dashboard-font"}>
                    This website allows you to interrogate individual chatbots that have access to the articles that support each of these 8 perspectives. This means they should give different answers to the same question (e.g. “what are the KPIs for marketing?) because they each represent a specific “school of thought” about the purpose and practice of marketing.
                </p>
                <p className={"dashboard-font"}>
                    This variety of response is a feature, not a bug. It illustrates that the “correct” approach to marketing depends on your context. The key challenge for every marketer is to work out what blend of the approaches is appropriate in your context
                </p>
                <p className={"dashboard-font-bold"} style={{ display: "none" }}>
                    The most important aspect of context is who you are talking to, and what they care about.
                </p>

                <div className="three-dots-container">
                    <div className="three-dots"></div>
                    <div className="three-dots"></div>
                    <div className="three-dots"></div>
                </div>

                <div className="dashboard-row">

                    <div className="dashboard-col">
                        <div className="dashboard-col-header-container">
                            <p className={"dashboard-strategy-header-font"}>
                                I am talking to my CEO about <a className="dashboard-underline-text">why</a> marketing contributes to our success as a business.
                            </p>
                        </div>
                        <div className="dashboard-col-strategies-container">
                            <ClickableStrategy text={"Strategic Rationale"} onClick={() => navigate("/dashboard/strategic")} />
                        </div>
                    </div>

                    <div className="dashboard-center-col">
                        <div className="dashboard-col-header-container">
                            <p className={"dashboard-strategy-header-font"}>
                                I am determining <a className="dashboard-underline-text">how</a> to operationalize marketing so that I am most effective in attracting and retaining customers:
                            </p>
                        </div>
                        <div className="dashboard-col-strategies-container">
                            <div className="dashboard-col-strategies-row">
                                <div className="dashboard-col-strategies-left-col">
                                    <ClickableStrategy text={"Product Marketing"} onClick={() => navigate("/dashboard/product-marketing")} />
                                </div>
                                <div className="dashboard-col-strategies-right-col">
                                    <ClickableStrategy text={"Account Based Marketing"} onClick={() => navigate("/dashboard/account-based-marketing")} />
                                </div>
                            </div>
                            <div className="dashboard-col-strategies-row">
                                <div className="dashboard-col-strategies-left-col">
                                    <ClickableStrategy text={"Customer Cohort Marketing"} onClick={() => navigate("/dashboard/customer-cohort-marketing")} />
                                </div>
                                <div className="dashboard-col-strategies-right-col">
                                    <ClickableStrategy text={"Performance Marketing"} onClick={() => navigate("/dashboard/performance-marketing")} />
                                </div>
                            </div>
                            <div className="dashboard-col-strategies-row">
                                <div className="dashboard-col-strategies-left-col">
                                    <ClickableStrategy text={"Brand Marketing"} onClick={() => navigate("/dashboard/brand-marketing")} />
                                </div>
                                <div className="dashboard-col-strategies-right-col">
                                    <ClickableStrategy text={"Employee-Led Marketing"} onClick={() => navigate("/dashboard/employee-centric-marketing")} />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="dashboard-col">
                        <div className="dashboard-col-header-container">
                            <p className={"dashboard-strategy-header-font"}>
                                I am talking to my CFO about <a className="dashboard-underline-text">why</a> marketing will improve the cash flow profile of the business
                            </p>
                        </div>
                        <div className="dashboard-col-strategies-container">
                            <ClickableStrategy text={"Financial Rationale"} onClick={() => navigate("/dashboard/financial")} />
                        </div>
                    </div>

                </div>

                <div className="dashboard-row" style={{ marginTop: "0px" }}>
                    <p className="dashboard-font">Click on the link that best corresponds to your interest.</p>
                </div>
            </div>

        </DashboardWrapper>
    )
}


const ClickableStrategy = ({ text, onClick }) => {
    return (
        <div className="clickable-strategy-container" onClick={onClick}>
            <p className="clickable-strategy-text">{text}</p>
        </div>
    )
}

export default Dashboard;


/*
<ul>
                            <li className={`regular-font-${theme.theme}`}>Product marketing</li>
                            <li className={`regular-font-${theme.theme}`}>Account based marketing</li>
                            <li className={`regular-font-${theme.theme}`}>Customer cohort marketing</li>
                            <li className={`regular-font-${theme.theme}`}>Performace marketing</li>
                            <li className={`regular-font-${theme.theme}`}>Brand marketing</li>
                            <li className={`regular-font-${theme.theme}`}>Employee-centric marketing</li>
                        </ul>
                        */