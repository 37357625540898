
const { REACT_APP_SERVICE_BASE_URL, REACT_APP_API_KEY, REACT_APP_API_SECRET } = process.env;

const authToken = btoa(unescape(encodeURIComponent(`${REACT_APP_API_KEY}:${REACT_APP_API_SECRET}`)));


export async function createChatThread(
    knowledgeBaseIds, page, accountID, systemMessage = "", model = "gpt-4", temperature = 0
) {

    const payload = {
        knowledge_base_ids: knowledgeBaseIds,
        model: model,
        temperature: temperature,
        system_message: systemMessage,
    }

    const response = await fetch(
        `${REACT_APP_SERVICE_BASE_URL}/chat/threads`,
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": authToken
            },
            body: JSON.stringify({
                default_options: payload,
                supp_id: `${page}:${accountID}`
            })
        }
    )

    const resData = await response.json();
    console.log(resData)
    return [resData, response.status];

}


export async function listChatThreads(
    nextPageToken, page, accountID
) {

    let url = "";
    url = `${REACT_APP_SERVICE_BASE_URL}/chat/threads?supp_id=${page}:${accountID}`;
    if (nextPageToken !== undefined && nextPageToken !== null) {
        url += `&next_page_token=${nextPageToken}`
    }

    const response = await fetch(
        url,
        {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": authToken
            }
        }
    )

    const resData = await response.json();
    return [resData, response.status];

}


export async function getChatThreadInteractions(idToken, threadId, nextPageToken = null) {

    let url = `${REACT_APP_SERVICE_BASE_URL}/chat/threads/${threadId}/interactions?limit=100`;
    if (nextPageToken !== null) {
        url += `&next_page_token=${nextPageToken}`;
    }

    const response = await fetch(
        url,
        {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": authToken
            }
        }
    )

    const resData = await response.json();
    console.log("interactions", resData)
    return [resData, response.status];

}


export async function updateChatThread(
    idToken, threadId, knowledgeBaseIds, model = "gpt-3.5-turbo", temperature = 0, systemMessage = "",
    responseLength = "medium", useWebSearch = null, webSearchPresetId = null
) {

    let formattedTemperature = temperature;
    if (typeof (temperature) === "string") {
        formattedTemperature = parseFloat(temperature);
    }
    if (formattedTemperature < 0.00001) {
        formattedTemperature = 0.00001;
    }
    if (temperature > 0.99999) {
        formattedTemperature = 0.99999;
    }

    let payload = {
        knowledge_base_ids: knowledgeBaseIds,
        model: model,
        temperature: formattedTemperature,
        system_message: systemMessage,
        response_length: responseLength
    }
    console.log("payload", payload)

    if (useWebSearch !== null) {
        payload.use_web_search = useWebSearch;
        if (webSearchPresetId !== null && webSearchPresetId !== "none") {
            payload.web_search_config = { web_search_preset_id: webSearchPresetId };
        }
    }

    const response = await fetch(
        `${REACT_APP_SERVICE_BASE_URL}/chat/threads/${threadId}`,
        {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Authorization": authToken
            },
            body: JSON.stringify({
                default_options: payload
            })
        }
    )

    const resData = await response.json();
    console.log(resData)
    return [resData, response.status];

}


export async function getChatThreadById(idToken, threadId) {

    const response = await fetch(
        `${REACT_APP_SERVICE_BASE_URL}/chat/threads/${threadId}`,
        {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": authToken
            }
        }
    )

    const resData = await response.json();
    console.log("resData", resData)
    return [resData, response.status];

}


export async function getChatThreadResponse(threadId, input, knowledgeBaseIds, systemMessage) {


    const payload = {
        async: true,
        input: input,
        knowledge_base_ids: knowledgeBaseIds,
        model: "claude-3-opus",
        system_message: systemMessage
    }

    console.log("payload", payload)
    console.log("authToken", authToken)

    try {
        const response = await fetch(
            `${REACT_APP_SERVICE_BASE_URL}/chat/threads/${threadId}/get_response`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": authToken
                },
                body: JSON.stringify(payload)
            }
        );

        if (!response.ok) {
            // Handle other HTTP errors
            //throw new Error(`HTTP error! status: ${response.status}`);
            const resData = await response.json();
            console.log("response.error", resData);
            console.log("response.status", response.status);
            return [{}, response.status];
        } else {
            // If the response is ok, parse the response body as JSON
            const resData = await response.json();
            console.log("response.status", response.status);
            return [resData, response.status];
            // Do something with resData
        }
    } catch (error) {
        // Handle network errors or errors thrown from the above blocks
        console.log('Fetch error:', error.message);
        return [{}, 403];
    }


}


export async function pollChatResponse(idToken, pollURL) {

    const response = await fetch(
        pollURL,
        {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": authToken
            }
        }
    )

    const resData = await response.json();
    console.log("poll response", resData)
    return [resData, response.status];

}


export async function deleteChatThread(idToken, threadId) {

    const response = await fetch(
        `${REACT_APP_SERVICE_BASE_URL}/chat/threads/${threadId}`,
        {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": authToken
            }
        }
    )

    const status = response.status;
    console.log(status)
    return status;

}


export async function renameChatThread(idToken, threadId, newTitle) {

    const payload = { title: newTitle };

    const response = await fetch(
        `${REACT_APP_SERVICE_BASE_URL}/chat/threads/${threadId}`,
        {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Authorization": idToken
            },
            body: JSON.stringify(payload)
        }
    )

    const resData = await response.json();
    console.log(resData)
    return [resData, response.status];

}