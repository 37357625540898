import { listChatThreads, getChatThreadInteractions } from '../../services/chat';
import { fetchUsage } from '../../services/auth';

function getNumMessagesFromThread(interactions) {

    let totalDailyChatMessages = 0;
    let totalWeeklyChatMessages = 0;

    // Get the number of messages that occurred in the past 24 hours
    if (interactions.length > 0) {
        // Get the timestamps of each message
        for (let interaction of interactions) {
            const timestamp = interaction.model_response.timestamp;
            const date = new Date(timestamp * 1000); // Convert to milliseconds
            const now = new Date();
            const diff = now - date;
            const diffHours = diff / 1000 / 60 / 60;
            if (diffHours < 24) {
                totalDailyChatMessages += 1;
            }
            if (diffHours < 168) {
                totalWeeklyChatMessages += 1;
            }
        }
    }

    return [totalDailyChatMessages, totalWeeklyChatMessages];

}


async function getThread(chatThreads) {
    let totalDailyChatMessages = 0;
    let totalWeeklyChatMessages = 0;

    for (let thread of chatThreads) {
        const threadID = thread.id;
        const [threadData, status] = await getChatThreadInteractions("", threadID);
        let nextPageTokenThread = threadData.next_page_token;
        const interactions = threadData.interactions;
        const [dailyChatMessages, weeklyChatMessages] = getNumMessagesFromThread(interactions)
        totalDailyChatMessages += dailyChatMessages;
        totalWeeklyChatMessages += weeklyChatMessages;

        while (nextPageTokenThread !== null) {
            console.log("getting next page")
            const [threadDataNext, status] = await getChatThreadInteractions("", threadID, nextPageTokenThread);
            const interactionsNext = threadDataNext.interactions;
            nextPageTokenThread = threadDataNext.next_page_token;
            const [dailyChatMessagesNext, weeklyChatMessagesNext] = getNumMessagesFromThread(interactionsNext)
            totalDailyChatMessages += dailyChatMessagesNext;
            totalWeeklyChatMessages += weeklyChatMessagesNext;
        }

    }

    return [totalDailyChatMessages, totalWeeklyChatMessages];

}

export async function getUsage() {
    // Define the start timestamp as one week ago

    const now = new Date();
    const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60);
    const endTime = now.getTime() + 24 * 60 * 60;
    const usageData = await fetchUsage(oneWeekAgo, endTime);
    console.log("usageData", usageData)
    return usageData;
}

export async function getTotalChatMessages(accountID) {
    let nextPageToken = null;
    let totalDailyChatMessages = 0
    let totalWeeklyChatMessages = 0
    //const accountID = sessionStorage.getItem('accountID')

    const pages = ["ceo", "cfo", "account-based-marketing", "customer-cohort-marketing", "performance-marketing", "product-marketing", "brand-marketing", "employee-centric-marketing"];

    for (let page of pages) {

        let [resData, status] = await listChatThreads(nextPageToken, page, accountID);
        nextPageToken = resData.next_page_token;
        const chatThreads = resData.chat_threads;

        const [dailyChatMessages, weeklyChatMessages] = await getThread(chatThreads, null)
        totalDailyChatMessages += dailyChatMessages;
        totalWeeklyChatMessages += weeklyChatMessages;

        while (nextPageToken !== null) {

            console.log("Getting next page of chat threads")
            let [resData, status] = await listChatThreads(nextPageToken, page, accountID);
            nextPageToken = resData.next_page_token;
            const chatThreads = resData.chat_threads;

            const [dailyChatMessages, weeklyChatMessages] = await getThread(chatThreads, null)
            totalDailyChatMessages += dailyChatMessages;
            totalWeeklyChatMessages += weeklyChatMessages;

        }

    }

    console.log("totalDailyChatMessages", totalDailyChatMessages)
    console.log("totalWeeklyChatMessages", totalWeeklyChatMessages)
    return [totalDailyChatMessages, totalWeeklyChatMessages];

}