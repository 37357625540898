import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconContext } from 'react-icons';
import { FiLogOut } from "react-icons/fi";
import { IoIosSettings } from "react-icons/io";
import { FaBoxOpen } from "react-icons/fa";
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoHome } from "react-icons/io5";

import { MdAccountCircle, MdSupervisorAccount } from "react-icons/md";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaChartLine, FaMoneyBillWave } from "react-icons/fa";
import { GiBrain } from "react-icons/gi";
import { IoPricetag } from "react-icons/io5";
import { BsPersonHeart } from "react-icons/bs";

import { useLogoutHandler } from '../../logic/auth/auth';

import T2cLogo from '../../assets/t2c-small-logo.png';

import './Sidenav.css';


const Sidenav = (props) => {

    const howPages = [
        { title: "Strategic", name: "ceo", path: "/dashboard/strategic", icon: <GiBrain /> },
        { title: "Financial", name: "cfo", path: "/dashboard/financial", icon: <FaMoneyBillWave /> },
    ]

    const whyPages = [
        { title: "Product", name: "product-marketing", path: "/dashboard/product-marketing", icon: <FaBoxOpen /> },
        { title: "Account based", name: "account-based-marketing", path: "/dashboard/account-based-marketing", icon: <MdAccountCircle /> },
        { title: "Customer cohort", name: "customer-cohort-marketing", path: "/dashboard/customer-cohort-marketing", icon: <FaPeopleGroup /> },
        { title: "Performance", name: "performance-marketing", path: "/dashboard/performance-marketing", icon: <FaChartLine /> },
        { title: "Brand", name: "brand-marketing", path: "/dashboard/brand-marketing", icon: <IoPricetag /> },
        { title: "Employee-led", name: "employee-centric-marketing", path: "/dashboard/employee-centric-marketing", icon: <BsPersonHeart /> },
    ]

    const navigate = useNavigate();

    const { logout } = useLogoutHandler();

    const [showSidenav, setShowSidenav] = useState(false);
    const [showAccountOptions, setShowAccountOptions] = useState(false);

    const accountOptionsRef = useRef();
    const nameRef = useRef();

    useEffect(() => {
        function handleClickOutside(event) {
            if (accountOptionsRef.current && (!accountOptionsRef.current.contains(event.target) && !nameRef.current.contains(event.target))) {
                // Hide the help box when the user clicks outside of it, as long as it isn't the ellipsis
                // The box is already hidden when the ellipsis is clicked
                setShowAccountOptions(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [accountOptionsRef]);

    return (
        <div className={`sidenav-container${props.showBanner ? "" : " sidenav-container-no-banner"}`}
            style={{ backgroundColor: "#FFFFFF" }}>

            <div className="sidenav-main-content-container">

                <div className="sidenav-header">
                    <div style={{ display: "flex" }}>
                        <img src={T2cLogo} className="sidenav-logo" onClick={() => navigate('/dashboard')} />
                    </div>
                    <div className="sidenav-tablet-logo-menu-icon-container">

                        <div className="sidenav-menu-icon-container" onClick={() => setShowSidenav(!showSidenav)}>
                            <IconContext.Provider
                                value={{ className: "sidenav-menu-icon" }}>
                                <div>
                                    <GiHamburgerMenu />
                                </div>
                            </IconContext.Provider>
                        </div>

                    </div>
                </div>

                <div className="sidenav-section">
                    <div className="sidenav-section-header-container">
                        <p className="sidenav-section-header-text">
                            Home
                        </p>
                    </div>

                    <div className={`sidenav-row${props.screen === "dashboard" ? ` sidenav-row-selected` : ""}`} onClick={() => navigate("/dashboard")} >
                        <IconContext.Provider
                            value={{ className: ((`sidenav-icon`) + (props.screen === 'dashboard' ? ` sidenav-icon-selected` : "")) }}>
                            <div>
                                <IoHome />
                            </div>
                        </IconContext.Provider>
                        <p className={(showSidenav ? `sidenav-text-opened ` : "") + (props.screen === 'dashboard' ? `sidenav-text-selected` : `sidenav-text`)}>
                            Home
                        </p>
                    </div>

                </div>

                <div className="sidenav-section">
                    <div className="sidenav-section-header-container">
                        <p className="sidenav-section-header-text">
                            2 Whys
                        </p>
                    </div>

                    {howPages.map((page, index) =>
                        <div className={`sidenav-row${props.screen === page.name ? ` sidenav-row-selected` : ""}`} onClick={() => navigate(page.path)} key={index}>
                            <IconContext.Provider
                                value={{ className: ((`sidenav-icon`) + (props.screen === page.name ? ` sidenav-icon-selected` : "")) }}>
                                <div>
                                    {page.icon}
                                </div>
                            </IconContext.Provider>
                            <p className={(showSidenav ? `sidenav-text-opened ` : "") + (props.screen === page.name ? `sidenav-text-selected` : `sidenav-text`)}>
                                {page.title}
                            </p>
                        </div>
                    )}

                </div>

                <div className="sidenav-section">
                    <div className="sidenav-section-header-container">
                        <p className="sidenav-section-header-text">
                            6 Hows
                        </p>
                    </div>

                    {whyPages.map((page, index) =>
                        <div className={`sidenav-row${props.screen === page.name ? ` sidenav-row-selected` : ""}`} onClick={() => navigate(page.path)} key={index}>
                            <IconContext.Provider
                                value={{ className: ((`sidenav-icon`) + (props.screen === page.name ? ` sidenav-icon-selected` : "")) }}>
                                <div>
                                    {page.icon}
                                </div>
                            </IconContext.Provider>
                            <p className={(showSidenav ? `sidenav-text-opened ` : "") + (props.screen === page.name ? `sidenav-text-selected` : `sidenav-text`)}>
                                {page.title}
                            </p>
                        </div>
                    )}

                </div>

            </div>

            <div className={"sidenav-row-bottom"}>

                {/*showAccountOptions &&
                    <div className={`sidenav-account-options-container`} ref={accountOptionsRef}>
                        <div className={`sidenav-row`} onClick={() => logout()}>
                            <IconContext.Provider
                                value={{ className: `sidenav-icon` }}>
                                <div>
                                    <FiLogOut />
                                </div>
                            </IconContext.Provider>
                            <p className={(showSidenav ? `sidenav-text-opened sidenav-text-selected` : "sidenav-text")}>
                                Log out
                            </p>
                        </div>
                    </div>
                    */}

                <div className={`sidenav-name-container`}
                    onClick={() => logout()}>
                    <div className={`sidenav-row`}>
                        <IconContext.Provider
                            value={{ className: `sidenav-icon` }}>
                            <div>
                                <FiLogOut />
                            </div>
                        </IconContext.Provider>
                        <p className={(showSidenav ? `sidenav-text-opened sidenav-text-selected` : "sidenav-text")}>
                            Log out
                        </p>
                    </div>
                </div>

                <div className={`sidenav-name-container`} style={{ display: "none" }}
                    onClick={() => setShowAccountOptions(true)} ref={nameRef}>
                    <div className="user-name-icon">
                        <p className={`user-name-icon-text`}>N</p>
                    </div>
                    <div>
                        <p className={`user-name-text`}>Nick McCormick</p>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default Sidenav;