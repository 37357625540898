import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

import * as accountServices from '../../services/account';
import * as authServices from '../../services/auth';

import { getTotalChatMessages, getUsage } from './limits';

import * as authActions from '../../store/actions/auth';


export function getTotalWeeklyCost(chatEvents) {
    console.log("chatEvents", chatEvents)
    let totalCost = 0;
    // We care about the total cost in the past week
    const currentTimestamp = new Date();
    // Conver to milliseconds
    let timestamp = currentTimestamp.getTime();
    for (let chatEvent of chatEvents) {
        timestamp = chatEvent.created_on * 1000; // Convert to milliseconds
        if (currentTimestamp - timestamp < 7 * 24 * 60 * 60 * 1000) {
            totalCost += chatEvent.cost;
        }
    }
    console.log("totalCost", totalCost)
    return totalCost;
}


export function useLoginHandler(setIsLoading) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    async function getAccountID(idToken) {
        const data = await authServices.getUserAccountID(idToken);
        //console.log("data", data)
        const accountID = data.account_id;
        const expiration = 1708208552 * 1000 // data.expiration * 1000; // Convert to milliseconds
        const expiryTime = new Date(expiration);

        const [totalDailyChatMessages, totalWeeklyChatMessages] = await getTotalChatMessages(accountID);
        sessionStorage.setItem('totalDailyChatMessages', totalDailyChatMessages);
        sessionStorage.setItem('totalWeeklyChatMessages', totalWeeklyChatMessages);

        // Save the expiration time to session storage
        // Create a date that is 5 minutes from now
        const now = new Date();
        sessionStorage.setItem('expiryTime', expiryTime.toISOString());
        //dispatch(authActions.setAutoLogoutTimer(expiration - new Date(), history));

        // Save this data to both session storage and the redux store
        sessionStorage.setItem('accountID', accountID);
        // Get the api keys now since it relies on the account ID
        //getUserAccountInfo(accountID, idToken);

        const usageData = await getUsage();
        const chatEvents = usageData.chat_request_events;
        const totalWeeklyCost = getTotalWeeklyCost(chatEvents);
        console.log("totalWeeklyCost", totalWeeklyCost)
        // Save this data to both session storage and the redux store
        sessionStorage.setItem('totalWeeklyCost', totalWeeklyCost);

        navigate('/dashboard');
    }

    async function getUserAccountInfo(accountID, idToken) {
        const accountInfo = await accountServices.getAccountInfo(accountID, idToken);
        // Save this data to both session storage and the redux store
        sessionStorage.setItem('accountInfo', JSON.stringify(accountInfo));
    }

    async function getLoginInfo(idToken) {
        setIsLoading(true);
        dispatch(authActions.setIdToken(idToken))
        // Save the id token to session storage
        sessionStorage.setItem('idToken', idToken);
        getAccountID(idToken);
    }

    return { getLoginInfo }

}


export function useAutoLogout(timeout) {

    const navigate = useNavigate();

    useEffect(() => {
        let logoutTimer;

        const resetTimer = () => {
            clearTimeout(logoutTimer);
            logoutTimer = setTimeout(() => {
                // Perform logout operation here. For example:
                console.log('Logging out due to inactivity');
                // Navigate to login or logout page
                navigate('/login');
            }, timeout);
        };

        // Reset the timer on these events
        window.addEventListener('load', resetTimer);
        document.addEventListener('mousemove', resetTimer);
        document.addEventListener('mousedown', resetTimer);
        document.addEventListener('click', resetTimer);
        document.addEventListener('scroll', resetTimer);
        document.addEventListener('keypress', resetTimer);

        resetTimer(); // Initialize timer on component mount

        return () => {
            // Cleanup
            clearTimeout(logoutTimer);
            window.removeEventListener('load', resetTimer);
            document.removeEventListener('mousemove', resetTimer);
            document.removeEventListener('mousedown', resetTimer);
            document.removeEventListener('click', resetTimer);
            document.removeEventListener('scroll', resetTimer);
            document.removeEventListener('keypress', resetTimer);
        };
    }, [navigate, timeout]);

}


export function useLogoutHandler() {

    let navigate = useNavigate();
    const dispatch = useDispatch();

    function logout() {
        dispatch(authActions.logout(navigate));
    }

    return { logout }

}