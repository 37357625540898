import React from 'react';

import Sidenav from './Sidenav';

import './DashboardWrapper.css';

const DashboardWrapper = (props) => {

    return (
        <div className="dashboard-wrapper-section">

            <div className="small-screen-warning">
                <p className={`semi-bold-font-light`}>
                    This web-app is not optimized for small screens. Please use a larger screen to view this web-app.
                </p>
            </div>

            <div>
                <div className="dashboard-wrapper-container"
                    style={{ ...props.customContainerStyle, ...{ backgroundColor: "#FAFAFA" } }}>
                    <div className={`dashboard-wrapper-sidenav-container`}>
                        <Sidenav screen={props.screen} />
                    </div>
                    <div style={props.customMainContainerStyle}
                        className={`dashboard-wrapper-main-content-container`}>
                        {props.children}
                    </div>
                </div>
            </div>

        </div>
    )

}

export default DashboardWrapper;
