import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import { ThemeProvider } from './context/theme';
import MainNavigator from './navigation/MainNavigator';

import authReducer from './store/reducers/auth';
import chatReducer from './store/reducers/chat';

const store = configureStore({
  reducer: {
    auth: authReducer,
    chat: chatReducer,
  }
})

function App() {

  return (
    <ThemeProvider>
      <HelmetProvider>
        <Provider store={store}>
          <MainNavigator />
        </Provider>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;

