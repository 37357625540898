import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from "react-router-dom";

import Home from '../screens/home/Home';
import LoginRedirect from '../screens/auth/LoginRedirect';


const AuthNavigator = () => {

    const [isAuthenticated, setIsAuthenticated] = useState(null);

    // Get the api key from redux
    const idTokenFromStorage = sessionStorage.getItem('idToken');
    const idToken = useSelector(state => state.auth.idToken);

    useEffect(() => {
        if (idTokenFromStorage === null && idToken === null) {
            setIsAuthenticated(false);
        } else if (idToken === null && idTokenFromStorage !== null) {
            // This means the page was refreshed, and the session is still valid
            setIsAuthenticated(true);
        } else if (idToken !== null) {
            // This means the page was refreshed, and the session is still valid
            setIsAuthenticated(true);
        }
    }, [idTokenFromStorage, idToken])

    if (isAuthenticated === null) {
        return null;
    } else {
        return (
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login-redirect" element={<LoginRedirect />} />
                <Route path="/signup-redirect" element={<LoginRedirect />} />
                <Route path="/*" element={<Navigate to="/" />} />
            </Routes>
        )
    }

}

// <Route path="/login-redirect" element={isAuthenticated ? <Navigate to="/dashboard/chat" /> : <LoginRedirect />} />


export default AuthNavigator;