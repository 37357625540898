import React, { useContext } from 'react';

import { IconContext } from 'react-icons';
import { HiPlus } from 'react-icons/hi';

import { ThemeContext } from '../../context/theme';

import './CustomButton.css';

export const CustomButton = ({ onClick, disabled, customStyle, customButtonStyle, buttonText }) => {

    const [{ theme }, _] = useContext(ThemeContext);

    return (
        <div className={disabled === true ? `main-button-container main-button-container-inactive-${theme.theme}` : `main-button-container`}
            onClick={disabled === true ? null : onClick}
            style={customStyle}>
            <button
                className={disabled === true ? "button button-inactive" : "button"}
                disabled={disabled === undefined ? false : disabled}
                style={customButtonStyle}>
                {buttonText}
            </button>
        </div>
    )

}

export const CustomButtonWithIcon = ({ onClick, disabled, customStyle, buttonText, icon }) => {

    const [{ theme }, _] = useContext(ThemeContext);

    return (
        <div className={disabled === true ? `main-button-container main-button-container-inactive-${theme.theme}` : `main-button-container`}
            onClick={disabled === true ? null : onClick}
            style={customStyle}>
            <div className="button-icon-container">
                {icon}
            </div>
            <div className="button-text-container">
                <button
                    className={disabled === true ? "button button-inactive" : "button"}
                    disabled={disabled === undefined ? false : disabled}>
                    {buttonText}
                </button>
            </div>
        </div>
    )

}


export const CustomAddButton = ({ onClick, disabled, customStyle, buttonText }) => {

    const [{ theme }, _] = useContext(ThemeContext);

    return (
        <div className={disabled === true ? `main-add-button-container main-add-button-container-inactive-${theme.theme}` : `main-add-button-container`}
            onClick={disabled === true ? null : onClick}
            style={customStyle}>
            <div className="button-icon-container">
                <IconContext.Provider value={{ className: "add-button-icon" }}>
                    <div>
                        <HiPlus />
                    </div>
                </IconContext.Provider>
            </div>
            <div className="button-text-container">
                <button
                    className={disabled === true ? "button button-inactive" : "button"}
                    disabled={disabled === undefined ? false : disabled}>
                    {buttonText}
                </button>
            </div>
        </div>
    )

}

export const CustomDeleteButton = ({ onClick, customStyle, buttonText }) => {

    const [{ theme }, _] = useContext(ThemeContext);

    return (
        <div className={`main-button-container delete-button-container`}
            onClick={onClick}
            style={customStyle}>
            <button
                className={"button"}
                disabled={false}>
                {buttonText}
            </button>
        </div>
    )

}

export const CustomCancelButton = ({ onClick, customStyle, buttonText }) => {

    const [{ theme }, _] = useContext(ThemeContext);

    return (
        <div className={"main-button-container cancel-button-container"}
            onClick={onClick}
            style={customStyle}>
            <button
                className={"button cancel-button"}
                disabled={false}>
                {buttonText}
            </button>
        </div>
    )

}


export const CustomViewCodeButton = ({ onClick, customStyle, buttonText, overrideTheme = null }) => {

    let [{ theme }, _] = useContext(ThemeContext);
    if (overrideTheme !== null) {
        theme = { theme: overrideTheme}
    }

    return (
        <div className={`view-code-button-container-${theme.theme}`}
            onClick={onClick}
            style={customStyle}>
            <button
                className={`button view-code-button-${theme.theme}`}
                disabled={false}>
                {buttonText}
            </button>
        </div>
    )

}