const { REACT_APP_SERVICE_BASE_URL_PRIVATE } = process.env;

export async function getAccountInfo(accountID, idToken) {

    const response = await fetch(`${REACT_APP_SERVICE_BASE_URL_PRIVATE}/accounts/${accountID}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": idToken
        },
    });
    const data = await response.json();
    console.log("accountInfo", data)
    return data;

}

