import { useState, useEffect } from 'react';

import * as chatServices from '../../services/chat';

import {
    initialChatMessages, knowledgeBaseIds, systemMessageHeader, ceoCfoSystemMessageHeader, systemMessages
} from '../../constants/chat';

const { REACT_APP_SERVICE_BASE_URL } = process.env;


export function createSystemMessage(page) {

    const marketingTypes = {
        "ceo": "CEO",
        "cfo": "CFO",
        "product-marketing": `product marketing`,
        "account-based-marketing": `account based marketing`,
        "customer-cohort-marketing": `customer cohort marketing`,
        "performance-marketing": `performance marketing`,
        "brand-marketing": `brand marketing`,
        "employee-centric-marketing": `employee centric marketing`,
    }

    const marketingType = marketingTypes[page];
    let systemMessage = "";
    if (page === "ceo" || page === "cfo") {
        systemMessage = ceoCfoSystemMessageHeader.replace("{role}", marketingType);
    } else {
        systemMessage = systemMessageHeader.replaceAll("{marketing_type}", marketingType);
    }

    systemMessage += `\n\n` + systemMessages[page]

    return systemMessage;

}

export function formatInitialMessage(currentThreadKnowledgeBases) {

    let message = "";
    if (currentThreadKnowledgeBases.length == 0) {
        message = "Hello! I'm a chatbot with access to your knowledge bases. You don't have any knowledge bases yet. Get started by going to the Knowledge Bases tab and creating one. \n\nYou can edit the knowledge bases I’m connected to by clicking the “Select knowledge bases” button in the bottom right corner of this page, and you can edit the LLM model configuration by clicking the “Edit LLM configuration” button."
    } else {
        message = "Hello! I'm a chatbot with access to the following knowledge bases: **";
        for (let i = 0; i < currentThreadKnowledgeBases.length; i++) {
            if (i < currentThreadKnowledgeBases.length - 1) {
                message += currentThreadKnowledgeBases[i].title + ", "
            } else {
                if (i == currentThreadKnowledgeBases.length - 1 && i > 0) {
                    message = message.slice(0, -2) // remove last comma and space
                    message += "**, and **"
                }
                message += currentThreadKnowledgeBases[i].title + "**"
            }
        }
        message += ". \n\nYou can edit the knowledge bases I'm connected to by clicking the “Select knowledge bases” button in the bottom right corner of this page, and you can edit the LLM model configuration by clicking the “Edit LLM configuration” button.\n\n";
    }

    return message;

}


export function formatTemperatureFromResponseType(responseType) {

    // The inputs can be "factual", "neutral", or "creative"
    // They map to 0.1, 0.5, and 0.9 respectively
    if (responseType === "factual") {
        return 0.1;
    } else if (responseType === "neutral") {
        return 0.5;
    } else if (responseType === "creative") {
        return 0.9;
    } else {
        return 0.5;
    }

}


export function sortChatThreadsByInteraction(threads) {
    // We want to sort the chat threads by the most recent interaction
    // If there isn't an interaction, we want to sort by the created_on date
    threads.sort((a, b) => {
        let aDate = 0;
        let bDate = 0;
        if (a.interactions !== undefined && a.interactions.interactions.length > 0) {
            aDate = a.interactions.interactions[a.interactions.interactions.length - 1].model_response.timestamp;
        } else if (a.recent_chat_history !== undefined && a.recent_chat_history.length > 0) {
            aDate = a.recent_chat_history[a.recent_chat_history.length - 1].model_response.timestamp;
        } else {
            aDate = a.created_on;
        }
        if (b.interactions !== undefined && b.interactions.interactions.length > 0) {
            bDate = b.interactions.interactions[b.interactions.interactions.length - 1].model_response.timestamp;
        } else if (b.recent_chat_history !== undefined && b.recent_chat_history.length > 0) {
            bDate = b.recent_chat_history[b.recent_chat_history.length - 1].model_response.timestamp;
        } else {
            bDate = b.created_on;
        }
        return bDate - aDate;
    })

    return threads;
}


export function breakChatThreadsIntoTimePeriods(chatThreads) {

    console.log("updating chat thread order", chatThreads)

    // We want 'Today', 'Previous 7 days', 'Previous 30 days', 'Previous 365 days', 'Older'
    // We want to sort the chat threads by the most recent interaction
    // If there isn't an interaction, we want to sort by the created_on date

    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let previous7Days = new Date(today);
    previous7Days.setDate(today.getDate() - 7);
    previous7Days.setHours(0, 0, 0, 0);
    let previous30Days = new Date(today);
    previous30Days.setDate(today.getDate() - 30);
    previous30Days.setHours(0, 0, 0, 0);
    let previous365Days = new Date(today);
    previous365Days.setDate(today.getDate() - 365);
    previous365Days.setHours(0, 0, 0, 0);

    let todayThreads = [];
    let previous7DaysThreads = [];
    let previous30DaysThreads = [];
    let previous365DaysThreads = [];
    let olderThreads = [];

    for (let i = 0; i < chatThreads.length; i++) {
        let thread = chatThreads[i];
        let lastInteractionDate = 0;

        // First check if the thread has any interactions
        if (thread.interactions !== undefined && thread.interactions.interactions.length > 0) {
            const lastInteraction = thread.interactions.interactions[thread.interactions.interactions.length - 1];
            lastInteractionDate = new Date(lastInteraction.model_response.timestamp * 1000);
        } else if (thread.recent_chat_history === undefined || thread.recent_chat_history.length == 0) {
            // If there is no recent_chat_history, we want to sort by the created_on date
            lastInteractionDate = new Date(thread.created_on * 1000);
        } else {
            const lastInteraction = thread.recent_chat_history[thread.recent_chat_history.length - 1];
            lastInteractionDate = new Date(lastInteraction.model_response.timestamp * 1000);
        }

        if (lastInteractionDate > today) {
            todayThreads.push(thread);
        } else if (lastInteractionDate > previous7Days) {
            previous7DaysThreads.push(thread);
        } else if (lastInteractionDate > previous30Days) {
            previous30DaysThreads.push(thread);
        } else if (lastInteractionDate > previous365Days) {
            previous365DaysThreads.push(thread);
        } else {
            olderThreads.push(thread);
        }
    }

    // Create the list of objects to return
    // If there are no threads for a time period, we don't want to include that time period
    let returnObject = [];
    if (todayThreads.length > 0) {
        // Sort the threads by the most recent interaction
        const sortedThreads = sortChatThreadsByInteraction(todayThreads);
        returnObject.push({ "key": "Today", "threads": sortedThreads });
    }
    if (previous7DaysThreads.length > 0) {
        // Sort the threads by the most recent interaction
        const sortedThreads = sortChatThreadsByInteraction(previous7DaysThreads);
        returnObject.push({ "key": "Previous 7 days", "threads": sortedThreads });
    }
    if (previous30DaysThreads.length > 0) {
        // Sort the threads by the most recent interaction
        const sortedThreads = sortChatThreadsByInteraction(previous30DaysThreads);
        returnObject.push({ "key": "Previous 30 days", "threads": sortedThreads });
    }
    if (previous365DaysThreads.length > 0) {
        // Sort the threads by the most recent interaction
        const sortedThreads = sortChatThreadsByInteraction(previous365DaysThreads);
        returnObject.push({ "key": "Previous 365 days", "threads": sortedThreads });
    }
    if (olderThreads.length > 0) {
        // Sort the threads by the most recent interaction
        const sortedThreads = sortChatThreadsByInteraction(olderThreads);
        returnObject.push({ "key": "Older", "threads": sortedThreads });
    }

    return returnObject;

}


export function breakResponseIntoChunks(currentText, newResponseText) {
    // For simulating streaming chat, we need to determine which words we need to display next

    // currentText will be the current text in the chat window
    // newResponseText will be the new response from the LLM, which will include the current text
    // We just care about the new text that needs to be added to the chat window
    const newTextAdded = newResponseText.slice(currentText.length);
    //console.log("newTextAdded", newTextAdded)

    // Split the new text into words
    const newWords = newTextAdded.split(" ");
    //console.log("newWords", newWords)

    const numWordsToAdd = newWords.length;

    // Randomly choose between 1 and 4 words to add to each chunk
    let chunks = [];
    let numWordsAdded = 0;
    while (numWordsAdded < numWordsToAdd) {
        let numWords = Math.floor(Math.random() * 4) + 1;
        let newString = newWords.slice(0, numWords).join(" ");
        newString += (numWordsAdded + numWords < numWordsToAdd ? " " : "")
        chunks.push(newString);
        newWords.splice(0, numWords);
        numWordsAdded += numWords;
    }

    return chunks;

}


export function formatWebSources(webSearchReferences = [], webSearchResults = []) {

    let sources = [];
    let numReferencesPerURL = {};

    if (webSearchReferences == undefined || webSearchResults == null) {
        return [];
    }

    for (let i = 0; i < webSearchReferences.length; i++) {
        const index = webSearchReferences[i];
        let source = webSearchResults[index];
        source["fullTitle"] = webSearchResults[index]["title"];
        source["url"] = webSearchResults[index]["url"]

        let displayedTitle = webSearchResults[index]["url"];
        let count = 1
        if (numReferencesPerURL[displayedTitle] !== undefined) {
            count = numReferencesPerURL[displayedTitle] + 1;
        }
        numReferencesPerURL[displayedTitle] = count;
        displayedTitle = (count > 1 ? displayedTitle + " (" + count + ")" : displayedTitle);
        // Cap the number of characters in the document title
        //let displayedTitle = documentTitle;
        if (displayedTitle.length > 35) {
            displayedTitle = displayedTitle.slice(0, 15) + "..." + displayedTitle.slice(-17);
        }

        source["title"] = displayedTitle;
        sources.push(source);
    }

    return sources;

}


export function formatSources(references = [], rankedResults = []) {

    // references is just an array of indices that correlates to the rankedResults array

    let sources = [];
    let numReferencesPerDocument = {};

    for (let i = 0; i < references.length; i++) {
        const index = references[i];
        const rankedResult = rankedResults[index]["metadata"];
        let displayedTitle = rankedResult["document"]["title"];
        let count = 1
        if (numReferencesPerDocument[displayedTitle] !== undefined) {
            count = numReferencesPerDocument[displayedTitle] + 1;
        }
        numReferencesPerDocument[displayedTitle] = count;
        displayedTitle = (count > 1 ? displayedTitle + " (" + count + ")" : displayedTitle);
        // Cap the number of characters in the document title
        //let displayedTitle = documentTitle;
        if (displayedTitle.length > 35) {
            displayedTitle = displayedTitle.slice(0, 15) + "..." + displayedTitle.slice(-17);
        }
        let source = rankedResult;
        source["document"]["title"] = rankedResult["document"]["title"];
        source["title"] = displayedTitle;
        sources.push(source);
    }

    return sources;
}

export function formatChatThreadTitle(chatThread, page) {
    // First check if the title exists
    if (chatThread.title == undefined || chatThread.title === "") {
        return "New Chat"
    }
    const title = chatThread.title;
    // Strip extra "" from the title
    if (title[0] === '"' && title[title.length - 1] === '"') {
        return title.slice(1, -1);
    }
    return title;

}


export function formatChatThread(chatThread, page) {
    // Format the data into a more usable format for the frontend
    if (chatThread == undefined) {
        return [];
    }
    let chatHistory = chatThread.interactions.interactions;
    console.log("chatHistory", chatHistory)
    // Sort the chat history by id (increasing order)
    chatHistory.sort((a, b) => {
        return a.id - b.id;
    });
    let formattedChatHistory = [];

    // Add in the initial chat message for this page
    /*const initialChatMessage = initialChatMessages[page];
    formattedChatHistory.push({
        id: "initial",
        prefix: "ai",
        content: initialChatMessage,
        sources: [],
        searchQueries: [],
        searchResults: [],
        webSources: [],
        webSearchQueries: [],
        webSearchResults: []
    });*/

    for (let i = 0; i < chatHistory.length; i++) {
        // Get the user message and AI response
        let userMessage = chatHistory[i]["user_input"]["content"];
        let aiResponse = chatHistory[i]["model_response"]["content"];

        const sources = formatSources(chatHistory[i]["references"], chatHistory[i]["ranked_results"]);
        let webSources = [];
        if (chatHistory[i]["web_search_references"] !== undefined && chatHistory[i]["web_search_results"] !== undefined) {
            webSources = formatWebSources(chatHistory[i]["web_search_references"], chatHistory[i]["web_search_results"])
        }

        const searchQueries = chatHistory[i]["search_queries"];
        // Extract the metadata from the ranked_results
        let searchResults = [];
        if (chatHistory[i]["ranked_results"] !== undefined && chatHistory[i]["ranked_results"] !== null) {
            for (let j = 0; j < chatHistory[i]["ranked_results"].length; j++) {
                searchResults.push(chatHistory[i]["ranked_results"][j]["metadata"]);
            }
        }
        const webSearchQueries = chatHistory[i]["web_search_queries"];
        const webSearchResults = chatHistory[i]["web_search_results"];

        const id = chatHistory[i]["id"];
        formattedChatHistory.push({ id: `user_${id}`, prefix: "user", content: userMessage, sources: [], searchQueries: [], searchResults: [], webSources: [], webSearchQueries: [], webSearchResults: [] });
        formattedChatHistory.push({ id: `ai_${id}`, prefix: "ai", content: aiResponse, sources: sources, searchQueries: searchQueries, searchResults: searchResults, webSources: webSources, webSearchQueries: webSearchQueries, webSearchResults: webSearchResults });
    }
    return formattedChatHistory;
}


export function sortChatThreadsByRecentlyUsed(chatThreads) {
    let sortedChatThreads = chatThreads.sort((a, b) => {
        return new Date((b.created_on - a.created_on));
    })
    return sortedChatThreads;
}


export function useChatHandler(knowledgeBases, setIsLoading, page) {

    const accountID = sessionStorage.getItem('accountID');
    const totalDailyChatMessages = sessionStorage.getItem('totalDailyChatMessages');
    const totalWeeklyChatMessages = sessionStorage.getItem('totalWeeklyChatMessages');
    const totalWeeklyCost = sessionStorage.getItem('totalWeeklyCost');

    // Get the idToken from the sessionStorage
    const idToken = sessionStorage.getItem('idToken');

    // Check if we have auto created a chat thread for this session
    //const didAutoCreateChatThread = sessionStorage.getItem('didAutoCreateChatThread');
    let didAutoCreateChatThread = false;

    // Get the chat threads from redux (these don't need to be in session storage since a page refresh should reload them)
    //const savedChatThreads = useSelector(state => state.chat.chatThreads);
    let savedChatThreads = null // sessionStorage.getItem("chatThreads");
    /*if (savedChatThreads !== null) {
        savedChatThreads = JSON.parse(savedChatThreads);
    }*/

    const [allChatThreads, setAllChatThreads] = useState([]);
    const [dateSortedChatThreads, setDateSortedChatThreads] = useState([]);

    const [chatMessages, setChatMessages] = useState([]);
    const [currentThread, setCurrentThread] = useState(null);
    const [showThinkingDots, setShowThinkingDots] = useState(false);
    const [selectedKnowledgeBases, setSelectedKnowledgeBases] = useState([]);

    const [isLoadingChatThreads, setIsLoadingChatThreads] = useState(false);

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [disableAutoScroll, setDisableAutoScroll] = useState(false);


    const systemMessage = createSystemMessage(page);

    async function createChatThread(isInitialChatThread = false) {
        const defaultKnowledgeBaseIds = []; // createDefaultChatThreadParameters(knowledgeBases);
        const [resData, status] = await chatServices.createChatThread(defaultKnowledgeBaseIds, page, accountID, systemMessage);
        // Update the chat threads in redux and the state variable
        if (status == 200) {
            // This will automatically be sorted how we want by putting the new chat at the beginning of the list
            let newChatThreads = [];
            // If we are auto-creating a new chat thread after the final one was deleted, 
            // we don't want to add the deleted one back. This is necessary because the state variable
            // doesn't update immediately, so we need to pass in the chat thread to ignore

            sessionStorage.setItem('didAutoCreateChatThread', "true");
            didAutoCreateChatThread = true;
            if (isInitialChatThread) {
                setAllChatThreads([resData]);
                setCurrentThread(resData);
                //sessionStorage.setItem("chatThreads", JSON.stringify([resData]));
                //dispatch(chatActions.setChatThreads([resData]));
            } else {
                newChatThreads = [resData, ...allChatThreads];
                setAllChatThreads(prevChatThreads => [resData, ...prevChatThreads])
                setCurrentThread(newChatThreads[0])
                //sessionStorage.setItem("chatThreads", JSON.stringify(newChatThreads));
                //dispatch(chatActions.setChatThreads(newChatThreads));
            }

        } else {
            setShowErrorModal(true);
            setErrorMessage("There was an error creating the chat thread. Please try again.");
        }
    }

    async function listChatThreads() {
        setIsLoading(true);
        setIsLoadingChatThreads(true);
        console.log("listing chat threads")
        const [chatThreads, status] = await chatServices.listChatThreads(null, page, accountID);
        console.log("chatThreads", chatThreads)
        let nextPageToken = chatThreads.next_page_token;
        let sortedChatThreads = sortChatThreadsByRecentlyUsed(chatThreads.chat_threads);

        // If there are no chat threads, create one
        console.log("didAutoCreateChatThread", didAutoCreateChatThread)
        if (sortedChatThreads.length == 0 && nextPageToken == null && !didAutoCreateChatThread) {
            console.log("creating new chat thread")
            didAutoCreateChatThread = true;
            await createChatThread();
            sessionStorage.setItem('didAutoCreateChatThread', "true");
            setIsLoading(false);
            setIsLoadingChatThreads(false);
            return;
        } else if (sortedChatThreads.length > 0) {
            setAllChatThreads(sortedChatThreads);
            //dispatch(chatActions.setChatThreads(sortedChatThreads));
        }

        while (nextPageToken != null) {
            const [newChatThreads, newStatus] = await chatServices.listChatThreads(nextPageToken, page, accountID);
            const newNextPageToken = newChatThreads.next_page_token;
            let newSortedChatThreads = sortChatThreadsByRecentlyUsed(newChatThreads.chat_threads);

            // Concatenate the chat threads
            sortedChatThreads = [...sortedChatThreads, ...newSortedChatThreads];
            setAllChatThreads(sortedChatThreads);
            nextPageToken = newNextPageToken;
        }

        // Check if the most recent chat thread has any chat messages in it
        if (sortedChatThreads.length > 0) {
            //sessionStorage.setItem("chatThreads", JSON.stringify(sortedChatThreads));
            //dispatch(chatActions.setChatThreads(sortedChatThreads));
            const timeSortedChatThreads = breakChatThreadsIntoTimePeriods(sortedChatThreads);
            console.log("timeSortedChatThreads dev", timeSortedChatThreads)
            // Get the first chat thread in the list
            const firstChatThread = timeSortedChatThreads[0]["threads"][0];
            console.log("firstChatThread", firstChatThread)
            const numInteractions = firstChatThread.num_interactions;
            /*if (numInteractions > 0 && (didAutoCreateChatThread !== "true" && didAutoCreateChatThread !== null)) {
                // Create a new chat thread
                console.log("didAutoCreateChatThread", didAutoCreateChatThread)
                console.log("creating a new chat thread")
                await createChatThread();
                sessionStorage.setItem('didAutoCreateChatThread', "true");
            }*/
        }

        setIsLoading(false);
        setIsLoadingChatThreads(false);

    }

    async function renameChatThread(newTitle, chatThreadID) {
        setIsLoading(true);
        console.log("chatThread", chatThreadID)
        const [resData, status] = await chatServices.renameChatThread(idToken, chatThreadID, newTitle)
        console.log("resData", resData)
        if (status === 200) {
            // Update the chat threads
            const newChatThreads = allChatThreads.map(thread => {
                if (thread.id === chatThreadID) {
                    return resData;
                }
                return thread;
            })
            setAllChatThreads(newChatThreads);
            //sessionStorage.setItem("chatThreads", JSON.stringify(newChatThreads));
            //dispatch(chatActions.setChatThreads(newChatThreads));
        }
        setIsLoading(false);
    }

    async function updateChatThread(
        knowledgeBaseIds, model, temperature, systemMessage, responseLength,
        useWebSearch, webSearchPresetId
    ) {
        const threadId = currentThread.id;
        // Turn the temperature into a float
        const formattedTemperature = formatTemperatureFromResponseType(temperature);
        const [resData, status] = await chatServices.updateChatThread(
            idToken, threadId, knowledgeBaseIds, model, formattedTemperature, systemMessage,
            responseLength, useWebSearch, webSearchPresetId
        );
        if (status == 200) {
            // Update this chat thread in the state variable and in redux
            // The resData will be the updated chat thread
            const newChatThreads = allChatThreads.map(thread => {
                if (thread.id === threadId) {
                    return resData;
                }
                return thread;
            })
            setAllChatThreads(newChatThreads);
            //sessionStorage.setItem("chatThreads", JSON.stringify(newChatThreads));
            //dispatch(chatActions.setChatThreads(newChatThreads));

            // Update the selected knowledge bases list
            const currentThreadKnowledgeBaseIds = resData.default_options.knowledge_base_ids;
            const currentThreadKnowledgeBases = knowledgeBases.filter(kb => currentThreadKnowledgeBaseIds.includes(kb.id));
            setSelectedKnowledgeBases(currentThreadKnowledgeBases);
        } else {
            setShowErrorModal(true);
            setErrorMessage("We were unable to update this chat thread at this time. For immediate assistance, please join our Discord server. You can also send an email to support@superpowered.ai")
        }

        return [resData, status];
    }

    async function getChatThreadById(threadId) {
        const [threadData, status] = await chatServices.getChatThreadById(idToken, threadId);
        if (status == 200) {
            return threadData;
        } else {
            return null
        }
    }

    async function sendMessage(message) {

        if (totalDailyChatMessages >= 20) {
            alert("You have reached the daily limit of 20 chat messages. Please try again tomorrow.")
            return;
        }
        if (totalWeeklyChatMessages >= 100) {
            alert("You have reached the weekly limit of 100 chat messages. Please try again next week.")
            return;
        }
        if (totalWeeklyCost >= 50) {
            alert("While this is a test environment, we have a weekly limit. Please try again next week.")
            return;
        }

        setDisableAutoScroll(false);

        // Update the redux store
        let maxID = "0000000000"
        if (currentThread.interactions !== undefined && currentThread.interactions.interactions.length > 0) {
            maxID = currentThread.interactions.interactions[currentThread.interactions.interactions.length - 1]["id"];
        }
        console.log("maxID", maxID)
        console.log("maxID+1", maxID + 1)
        const newID = (parseInt(maxID) + 1).toString().padStart(10, "0");

        // Update the chat messages in the state variable (only if the status is 200)
        const newChatMessage = { prefix: "user", content: message, sources: [], id: `user_${newID}` };
        const newChatMessages = [...chatMessages, newChatMessage];
        setChatMessages(newChatMessages);

        // Make this chat thread the first in the list
        const newThreads = allChatThreads.filter(thread => thread.id !== currentThread.id);
        const currentChatThread = allChatThreads.filter(thread => thread.id === currentThread.id)[0];
        newThreads.unshift(currentChatThread);
        setAllChatThreads(newThreads);
        setShowThinkingDots(true)

        // Check if we need to request this thread again in order to get the chat thread title
        let newThreadTitle = null;
        if (currentChatThread.title === undefined || currentChatThread.title === "") {
            const newThreadData = await getChatThreadById(currentThread.id);
            console.log("newThreadData", newThreadData)
            newThreadTitle = newThreadData.title;
        }

        const threadId = currentThread.id;
        const kbID = knowledgeBaseIds[page]
        let [resData, status] = await chatServices.getChatThreadResponse(threadId, message, [kbID], systemMessage);
        console.log("status", status)
        if (status > 299) {
            setShowThinkingDots(false);
            setShowErrorModal(true);
            setErrorMessage("There was an error sending your message. Please contact support@superpowered.ai for assistance, or for more immediate support, join our Discord.");
            return;
        }

        let responseStatus = resData["status"];
        const pollURL = resData["status_url"];

        // If the status isn't COMPLETE, then we need to keep polling until it is
        let pollCount = 0;
        let modelResponseText = "";
        while (responseStatus !== "COMPLETE" && pollCount < 200) {
            [resData, status] = await chatServices.pollChatResponse(
                idToken, pollURL
            );
            responseStatus = resData["status"];

            // Check for a status of FAILED
            if (responseStatus === "FAILED") {
                break;
            }
            pollCount += 1;

            // Update the message if the status is IN_PROGRESS, and there is a model response
            if (resData["response"]["interaction"]["model_response"] !== null && resData["response"]["interaction"]["model_response"]["content"] !== null) {
                const modelResponse = resData["response"]["interaction"]["model_response"]["content"];

                let chunks = breakResponseIntoChunks(modelResponseText, modelResponse)
                if (chunks.length > 0) {

                    const sleepTime = (500) / chunks.length;

                    setShowThinkingDots(false);

                    for (let i = 0; i < chunks.length; i++) {

                        // update the chat thread with the AI response in the state variable
                        const newAiResponse = {
                            prefix: "ai",
                            content: modelResponseText + chunks[i],
                            sources: [], // Don't show the sources yet
                            searchResults: [],
                            searchQueries: [],
                            //id: `ai_${resData["response"]["interaction"]["id"]}`
                        };
                        // newChatMessages won't get updated in this loop. It is a constant, not a state variable
                        const newChatMessagesWithAiResponse = [...newChatMessages, newAiResponse];
                        setChatMessages(newChatMessagesWithAiResponse);
                        modelResponseText += chunks[i];

                        await new Promise(r => setTimeout(r, sleepTime));
                    }

                }

            }

            if (responseStatus === "COMPLETE") {
                break;
            }
            // Sleep for 0.25 seconds
            if (resData["response"]["interaction"]["model_response"] == null || resData["response"]["interaction"]["model_response"]["content"] == null) {
                await new Promise(r => setTimeout(r, 500));
            }
        }

        // If the status still isn't COMPLETE, then we need to show an error message
        if (responseStatus !== "COMPLETE") {
            setShowThinkingDots(false)
            setShowErrorModal(true);
            setErrorMessage("We were unable to get a response from the AI at this time. For immediate assistance, please join our Discord server. You can also send an email to support@superpowered.ai")
            return;
        }

        console.log("resData", resData)
        resData = resData["response"];

        // Update the sources
        const sources = formatSources(
            resData["interaction"]["references"], resData["interaction"]["ranked_results"]
        );

        let webSources = [];
        if (resData["interaction"]["web_search_references"] !== undefined && resData["interaction"]["web_search_results"] !== undefined) {
            webSources = formatWebSources(resData["interaction"]["web_search_references"], resData["interaction"]["web_search_results"])
        }

        // Extract the metadata from the ranked_results
        let searchResults = [];
        if (resData["interaction"]["ranked_results"] !== undefined && resData["interaction"]["ranked_results"] !== null) {
            for (let j = 0; j < resData["interaction"]["ranked_results"].length; j++) {
                searchResults.push(resData["interaction"]["ranked_results"][j]["metadata"]);
            }
        }

        // update the chat thread with the AI response in the state variable
        const newAiResponse = {
            prefix: "ai",
            content: resData["interaction"]["model_response"]["content"],
            sources: sources,
            searchResults: searchResults,
            searchQueries: resData["interaction"]["search_queries"],
            webSources: webSources,
            webSearchQueries: resData["interaction"]["web_search_queries"],
            webSearchResults: resData["interaction"]["web_search_results"],
            id: `ai_${resData["interaction"]["id"]}`
        };
        const newChatMessagesWithAiResponse = [...newChatMessages, newAiResponse];
        console.log("Setting chat messages")
        console.log("newChatMessagesWithAiResponse", resData["interaction"]["model_response"]["content"])
        setChatMessages(newChatMessagesWithAiResponse);

        // Increment the total daily and weekly chat messages
        const newTotalDailyMessages = parseInt(totalDailyChatMessages) + 1;
        const newTotalWeeklyMessages = parseInt(totalWeeklyChatMessages) + 1;
        const newTotalWeeklyCost = parseFloat(totalWeeklyCost) + 0.16;
        console.log("newTotalWeeklyCost", newTotalWeeklyCost)
        sessionStorage.setItem('totalDailyChatMessages', newTotalDailyMessages);
        sessionStorage.setItem('totalWeeklyChatMessages', newTotalWeeklyMessages);
        sessionStorage.setItem('totalWeeklyCost', newTotalWeeklyCost);

        // This will be in the format "0000000001", "0000000011". We want to add 1 to this
        //const newID = (parseInt(maxID) + 1).toString().padStart(10, "0");
        let newChatThreads = allChatThreads.map(thread => {

            if (thread.id === threadId) {
                if (thread.interactions == undefined) {
                    console.log("thread", thread)
                    thread.interactions = { interactions: [] };
                }

                thread.interactions.interactions.push(resData["interaction"]);
                if (newThreadTitle !== null) {
                    thread.title = newThreadTitle;
                }
            }
            return thread;
        })
        //newChatThreads = formatChatThread(newChatThreads);
        setAllChatThreads(newChatThreads);
        setShowThinkingDots(false)
        //sessionStorage.setItem("chatThreads", JSON.stringify(newChatThreads));
        //dispatch(chatActions.setChatThreads(newChatThreads));
        //dispatch(accountActions.setBillingEvents(null)); // resetting the billing events so we request them again

    }

    async function deleteChatThread(chatThread) {
        setDisableAutoScroll(false);
        const status = await chatServices.deleteChatThread(idToken, chatThread.id);
        if (status == 204) {
            // Update the chat threads in redux and the state variable
            const newChatThreads = allChatThreads.filter(thread => thread.id !== chatThread.id);
            setAllChatThreads(newChatThreads);
            if (currentThread.id === chatThread.id) {
                // This will make sure we update the current thread to the most recent one
                setCurrentThread(null)
            }
            if (newChatThreads.length === 0) {
                await createChatThread(chatThread.id);
            }
        }
        return status;
    }


    async function updateThreadTitle(threadId) {

        setIsLoading(true);

        const controller = new AbortController();
        const { signal } = controller;

        const fetchPromise = fetch(`${REACT_APP_SERVICE_BASE_URL}/chat/threads/${threadId}`,
            {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": idToken
                }
            }, { signal });

        const timeoutPromise = new Promise((_, reject) =>
            setTimeout(() => {
                controller.abort();
                reject(new Error('Request timed out'));
                setIsLoading(false);
            }, 500)
        );

        Promise.race([fetchPromise, timeoutPromise])
            .then(response => {
                if (!response.ok) {
                    setIsLoading(false);
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Success:', data)
                const newThreadTitle = data.title;
                const newChatThreads = allChatThreads.map(thread => {
                    if (thread.id === threadId) {
                        thread.title = newThreadTitle;
                    }
                    return thread;
                })
                setAllChatThreads(newChatThreads);
                //sessionStorage.setItem("chatThreads", JSON.stringify(newChatThreads));
                //dispatch(chatActions.setChatThreads(newChatThreads));
                setIsLoading(false);
            })
            .catch(error => console.error('Error:', error));

    }

    async function requestMoreChats() {
        setIsLoading(true);
        setDisableAutoScroll(true);
        // Request the next page of chat interactions
        const [interactions, status] = await chatServices.getChatThreadInteractions(
            idToken, currentThread.id, currentThread.interactions.next_page_token
        );
        // Update the current thread
        let currentInteractions = currentThread.interactions.interactions;
        currentInteractions = currentInteractions.concat(interactions.interactions);
        currentThread.interactions.interactions = currentInteractions;

        // Update the next page token
        currentThread.interactions.next_page_token = interactions.next_page_token;

        // Set the chat messages to the current thread's messages
        const formattedChatMessages = formatChatThread(currentThread, page);
        setChatMessages(formattedChatMessages);

        // Update all threads
        const newChatThreads = allChatThreads.map(thread => {
            if (thread.id === currentThread.id) {
                thread = currentThread;
            }
            return thread;
        })
        // Update the redux store
        //sessionStorage.setItem("chatThreads", JSON.stringify(newChatThreads));
        //dispatch(chatActions.setChatThreads(newChatThreads));
        setIsLoading(false);
    }


    async function handleChatThreadChange() {

        setDisableAutoScroll(false);

        // First check if there are interactions in this thread
        setIsLoading(true);
        if (currentThread.interactions === undefined) {
            // We need to request the interactions for this thread
            const [interactions, status] = await chatServices.getChatThreadInteractions(idToken, currentThread.id);
            currentThread["interactions"] = interactions
        }

        // Set the current thread
        setCurrentThread(currentThread);
        // Update the allThreads state variable
        /*const newChatThreads = allChatThreads.map(thread => {
            if (thread.id === currentThread.id) {
                thread = currentThread;
            }
            return thread;
        })*/
        //setAllChatThreads(newChatThreads);

        // Set the chat messages to the current thread's messages
        const formattedChatMessages = formatChatThread(currentThread, page)

        // Update the selected knowledge bases list
        const currentThreadKnowledgeBaseIds = currentThread.default_options.knowledge_base_ids;
        const currentThreadKnowledgeBases = knowledgeBases.filter(kb => currentThreadKnowledgeBaseIds.includes(kb.id));

        setSelectedKnowledgeBases(currentThreadKnowledgeBases);
        setChatMessages(formattedChatMessages);
        console.log("setting is loading to false")
        setIsLoading(false);

        // Check if we need to request this thread again in order to get the chat thread title
        if (currentThread.title === undefined || currentThread.title === "") {
            // Make sure there are at least 2 messages in the thread (one user input, one ai response)
            if (formattedChatMessages.length > 1) {
                updateThreadTitle(currentThread.id);
            }
        }

    }

    useEffect(() => {
        // Get all of the chat threads if they are currently null
        if (savedChatThreads === null) {
            listChatThreads();
        } else if (currentThread === null) {
            const sortedChatThreads = sortChatThreadsByRecentlyUsed(savedChatThreads);
            console.log("setting chat threads from storage")
            setAllChatThreads(sortedChatThreads)
            //setCurrentThread(sortedChatThreads[0]);
        }
        // Add in a cleanup function
        return () => {
            // Just return
            console.log("");
        }
    }, [])

    useEffect(() => {
        // When all chat threads update, update the date sorted chat threads
        const timeSortedChatThreads = breakChatThreadsIntoTimePeriods(allChatThreads)
        setDateSortedChatThreads(timeSortedChatThreads);
        // Set the current thread to be the most recent one
        console.log("currentThread", currentThread)
        if (timeSortedChatThreads.length > 0 && currentThread === null) {
            console.log("setting current thread")
            setCurrentThread(timeSortedChatThreads[0].threads[0]);
        }
    }, [allChatThreads])

    useEffect(() => {
        // Set the chat messages to the current thread's messages
        if (currentThread !== null && currentThread !== undefined) {
            handleChatThreadChange();
        }
    }, [currentThread])


    return {
        createChatThread, updateChatThread, sendMessage, deleteChatThread, requestMoreChats,
        chatMessages, currentThread, setCurrentThread, showThinkingDots, renameChatThread,
        showErrorModal, setShowErrorModal, errorMessage, disableAutoScroll, isLoadingChatThreads, dateSortedChatThreads
    }

}
