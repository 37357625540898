import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useNavigate } from "react-router-dom";

import LoginRedirect from '../screens/auth/LoginRedirect';
import Dashboard from '../screens/dashboard/Dashboard';

// 2 Whys
import CeoChat from '../screens/dashboard/CeoChat';
import CfoChat from '../screens/dashboard/CfoChat';

// 6 Hows
import ProductMarketing from '../screens/dashboard/ProductMarketing';
import AccountBasedMarketing from '../screens/dashboard/AccountBasedMarketing';
import CustomerCohortMarketing from '../screens/dashboard/CustomerCohortMarketing';
import PerformanceMarketing from '../screens/dashboard/PerformanceMarketing';
import BrandMarketing from '../screens/dashboard/BrandMarketing';
import EmployeeCentricMarketing from '../screens/dashboard/EmployeeCentricMarketing';

import * as authActions from '../store/actions/auth';


const DashboardNavigator = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Get the api key from redux
    const idTokenFromStorage = sessionStorage.getItem('idToken');
    const idToken = useSelector(state => state.auth.idToken);

    useEffect(() => {
        if (idTokenFromStorage === null && idToken === null) {
            navigate('/');
            dispatch(authActions.logout((navigate)));
        } else if (idToken === null && idTokenFromStorage !== null) {
            // This means the page was refreshed, and the session is still valid
            dispatch(authActions.setIdToken(idTokenFromStorage));
        }
    }, [idTokenFromStorage, idToken])

    return (
        <Routes>
            <Route path="/login-redirect" element={<LoginRedirect />} />
            <Route path="/signup-redirect" element={<LoginRedirect />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/strategic" element={<CeoChat />} />
            <Route path="/dashboard/financial" element={<CfoChat />} />
            <Route path="/dashboard/product-marketing" element={<ProductMarketing />} />
            <Route path="/dashboard/account-based-marketing" element={<AccountBasedMarketing />} />
            <Route path="/dashboard/customer-cohort-marketing" element={<CustomerCohortMarketing />} />
            <Route path="/dashboard/performance-marketing" element={<PerformanceMarketing />} />
            <Route path="/dashboard/brand-marketing" element={<BrandMarketing />} />
            <Route path="/dashboard/employee-centric-marketing" element={<EmployeeCentricMarketing />} />
        </Routes>
    )

}

export default DashboardNavigator;