import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { lucario } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { BiCopy } from 'react-icons/bi';
import { IconContext } from 'react-icons';

import './ChatMessage.css';

const ChatMessage = (props) => {

    const textClassName = `chat-message-text-${props.rightOrLeft}`;
    const containerClassName = `chat-message-container chat-message-container-${props.rightOrLeft}`;

    const [showCopiedText, setShowCopiedText] = useState(false);

    function getMarkdownText() {
        const lines = props.message.split('\n');
        let code = '';
        let inCodeBlock = false;
        for (let line of lines) {
            if (line.startsWith('```')) {
                inCodeBlock = !inCodeBlock;
            } else if (inCodeBlock) {
                code += line + '\n';
            }
        }
        // Copy the code to clipboard
        navigator.clipboard.writeText(code);

        // Show the copied text for 2 seconds
        setShowCopiedText(true);
        setTimeout(() => {
            setShowCopiedText(false);
        }, 3000);

    }

    return (
        <div className={containerClassName}>
            <ReactMarkdown
                children={props.message}
                className={textClassName}
                components={{
                    code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || '')
                        return !inline && match ? (
                            <div>
                                {showCopiedText && <p className="copied-text">copied</p>}
                                <div className="copy-markdown-icon" onClick={() => getMarkdownText()}>
                                    <IconContext.Provider
                                        value={{
                                            size: "20px",
                                            color: "#e8e8e8",
                                        }}>
                                        <div>
                                            <BiCopy />
                                        </div>
                                    </IconContext.Provider>
                                </div>
                                <SyntaxHighlighter
                                    children={String(children).replace(/\n$/, '')}
                                    language={match[1]}
                                    style={lucario}
                                    customStyle={{ fontSize: "14px" }}
                                    PreTag="div"
                                    {...props}
                                />
                            </div>
                        ) : (
                            <code className={className} {...props}>
                                {children}
                            </code>
                        )
                    }
                }}
            />
        </div>
    )

}


export const ThinkingChatMessage = (props) => {

    const [message, setMessage] = useState('')

    useEffect(() => {
        let dots = 0;
        const intervalId = setInterval(() => {
            dots = (dots + 1) % 4;
            const newMessage = `${'.'.repeat(dots)}`;
            setMessage(newMessage)
        }, 250);
    }, [])

    if (!props.isVisible) {
        return (
            <div></div>
        )
    } else {
        return (
            <div className="thinking-chat-message">
                <p className="thinking-dots">{message}</p>
            </div>
        )
    }

}


export default ChatMessage;