import React from 'react';

import DashboardWrapper from '../../components/dashboard/DashboardWrapper';
import Chat from '../../components/chat/Chat';

const PerformanceMarketing = () => {

    return (
        <DashboardWrapper
            screen={"performance-marketing"}
            customMainContainerStyle={{ paddingTop: "0px", paddingLeft: "0px", paddingRight: "0px", maxWidth: "100%" }}>

            <Chat page={"performance-marketing"} />

        </DashboardWrapper>
    )

}

export default PerformanceMarketing;