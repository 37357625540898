import React, { useEffect, useState, useRef, useContext } from 'react';
import { IconContext } from 'react-icons';
import { FaTrash, FaPen } from 'react-icons/fa';
import { IoEllipsisVerticalSharp } from 'react-icons/io5';
import { BiCopy } from 'react-icons/bi';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { lucario } from 'react-syntax-highlighter/dist/cjs/styles/prism';

import { CustomButton, CustomAddButton, CustomCancelButton } from '../../components/general/CustomButton';
import ChatInput from '../../components/chat/ChatInput';
import DeleteConfirmationModal from '../../components/general/DeleteConfirmationModal';
import { ThinkingChatMessage } from '../../components/chat/ChatMessage';
import ErrorModal from '../../components/general/ErrorModal';
import LoadingSpinner from '../../components/general/LoadingSpinner';
import CustomModal from '../../components/general/CustomModal';
import ChatWelcomeMessage from '../../components/chat/ChatWelcomeMessage';
import CustomTextInput from '../../components/general/CustomTextInput';
import QueriesAndResultsModal from '../../components/chat/QueriesAndResultsModal';

import { ThemeContext } from '../../context/theme';

import { useChatHandler, formatChatThreadTitle } from '../../logic/chat/chat';
import { initialChatMessages } from '../../constants/chat';

import T2cLogo from '../../assets/t2c-small-logo.png';
import './Chat.css';


const Chat = ({ page }) => {

    const [{ theme }, _] = useContext(ThemeContext);

    const chatContainerRef = useRef(null);
    const [topMessageId, setTopMessageId] = useState(null);

    const [showQueriesAndResultsModal, setShowQueriesAndResultsModal] = useState(false);
    const [queries, setQueries] = useState([]);
    const [results, setResults] = useState([]);
    const [citedResults, setCitedResults] = useState([]);
    const [selectedSource, setSelectedSource] = useState(null);

    const [showChatThreadEditModal, setShowChatThreadEditModal] = useState(false);
    const [chatThreadToEdit, setChatThreadToEdit] = useState(null);

    // AI Settings
    const [message, setMessage] = useState("");

    const [chatThreadToDelete, setChatThreadToDelete] = useState(null);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const bannerShown = false;

    const {
        createChatThread, updateChatThread, sendMessage, deleteChatThread, requestMoreChats,
        chatMessages, currentThread, setCurrentThread, showThinkingDots, renameChatThread,
        showErrorModal, setShowErrorModal, errorMessage, disableAutoScroll, isLoadingChatThreads, dateSortedChatThreads
    } = useChatHandler([], setIsLoading, page);

    const messageRefs = chatMessages.reduce((acc, message) => {
        acc[message.id] = React.createRef();
        return acc;
    }, {});

    function showQueriesAndResults(queries, results, sources, source) {

        setQueries(queries);
        setResults(results);
        setCitedResults(sources);
        setSelectedSource(source);
        setShowQueriesAndResultsModal(true)

    }

    useEffect(() => {
        if (chatContainerRef.current && !disableAutoScroll) {
            const { scrollHeight } = chatContainerRef.current;
            chatContainerRef.current.scrollTop = scrollHeight + 100;
        } else if (chatContainerRef.current && disableAutoScroll) {
            // This is the case when someone clicks View previous chats
            // We only want to scroll to the top of the previous chats
            chatContainerRef.current.scrollTop = messageRefs[topMessageId].current.offsetTop - 100;
        }
    }, [chatMessages, showThinkingDots, chatContainerRef])


    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            const intersectingEntries = entries.filter(entry => entry.isIntersecting);

            if (intersectingEntries.length > 0) {
                const topEntry = intersectingEntries.reduce((top, entry) => (entry.boundingClientRect.top < top.boundingClientRect.top ? entry : top));
                setTopMessageId(topEntry.target.id);
            }
        }, {
            root: chatContainerRef.current,
            threshold: 1.0
        });

        Object.values(messageRefs).forEach(ref => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => {
            Object.values(messageRefs).forEach(ref => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, [chatMessages]);


    return (

        <div className="chat-main-section">

            {isLoading && <LoadingSpinner />}

            {showErrorModal &&
                <ErrorModal
                    isOpen={showErrorModal}
                    errorMessage={errorMessage}
                    onClose={() => setShowErrorModal(false)}
                />
            }

            {showDeleteConfirmationModal &&
                <DeleteConfirmationModal
                    isOpen={showDeleteConfirmationModal}
                    closeModal={() => setShowDeleteConfirmationModal(false)}
                    delete={() => { deleteChatThread(chatThreadToDelete); setShowDeleteConfirmationModal(false) }}
                    title={"Delete chat thread"}
                    confirmationText={`Are you sure you want to delete chat thread ${chatThreadToDelete.title}?`}
                />
            }

            {showQueriesAndResultsModal &&
                <QueriesAndResultsModal
                    onClose={() => setShowQueriesAndResultsModal(false)}
                    queries={queries}
                    citedResults={citedResults}
                    allResults={results}
                    theme={theme}
                    selectedSource={selectedSource}
                />
            }

            <div className={`chat-main-container${(bannerShown ? " chat-main-container-banner-shown" : "")}`}>
                <div className="chat-messages-container" ref={chatContainerRef}>

                    {(currentThread !== undefined && currentThread !== null && currentThread.interactions !== undefined && currentThread.interactions !== null && currentThread.interactions.next_page_token !== null && currentThread.interactions.next_page_token !== undefined) &&
                        <div className="view-previous-chats-container">
                            <p className={`view-previous-chats-text-${theme.theme}`} onClick={() => requestMoreChats()}>
                                View previous chats
                            </p>
                        </div>
                    }

                    {chatMessages !== null && chatMessages !== undefined && chatMessages.length === 0 &&
                        <ChatWelcomeMessage
                            logo={T2cLogo}
                            message={initialChatMessages[page]}
                        />
                    }

                    {chatMessages.map((chatMessage, index) =>
                        <div id={chatMessage["id"]} key={index} ref={messageRefs[chatMessage.id]}>
                            <ChatBox
                                aiOrUserMessage={chatMessage["prefix"]}
                                message={chatMessage["content"]}
                                sources={chatMessage["sources"]}
                                webSources={chatMessage["webSources"]}
                                chatMessage={chatMessage}
                                onClick={(searchQueries, searchResults, sources, source) => showQueriesAndResults(searchQueries, searchResults, sources, source)}
                            />
                        </div>
                    )}

                    {showThinkingDots && <div className={`chat-message-container-${theme.theme} ai-chat-message-container-${theme.theme}`}>
                        <div style={{ maxWidth: "850px", margin: "auto", width: "100%" }}>
                            <ThinkingChatMessage isVisible={showThinkingDots} />
                        </div>
                    </div>}

                </div>

                <div className="chat-send-message-container">
                    <ChatInput
                        sendDataToParent={(message) => setMessage(message)}
                        sendMessage={() => sendMessage(message)}
                        sendMessageDisabled={isLoading || showThinkingDots}
                    />
                </div>
            </div>

            <div className={`chat-sidebar-container-${theme.theme}${(bannerShown ? ` chat-sidebar-container-banner-shown` : "")}`}>
                <div className="new-chat-thread-button-container">
                    <CustomAddButton
                        buttonText={"New chat thread"}
                        onClick={() => createChatThread()}
                        disabled={(isLoading || showThinkingDots || isLoadingChatThreads)}
                    />
                </div>
                <div className="all-chat-threads-container">

                    {dateSortedChatThreads.map((chatThreadList, index) =>
                        <div key={index}>
                            <div className="chat-thread-date-container">
                                <p className={`chat-thread-date-text-${theme.theme}`}>
                                    {chatThreadList["key"]}
                                </p>
                                {chatThreadList["threads"].map((chatThread, chatThreadIndex) =>
                                    <ChatThread
                                        key={chatThreadIndex}
                                        disableClick={(isLoading || showThinkingDots)}
                                        isCurrentThread={currentThread === null ? false : (currentThread["id"] === chatThread["id"])}
                                        title={formatChatThreadTitle(chatThread)}
                                        onClick={() => setCurrentThread(chatThread)}
                                        onEditClick={() => { setShowChatThreadEditModal(true); setChatThreadToEdit(chatThread) }}
                                        onDeleteClick={() => { setShowDeleteConfirmationModal(true); setChatThreadToDelete(chatThread) }}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>

    )

}


const ChatBox = ({ aiOrUserMessage, message, sources, webSources, chatMessage, onClick }) => {
    // aiOrUserMessage is either "ai" or "user"

    const [{ theme }, _] = useContext(ThemeContext);

    const [showCopiedText, setShowCopiedText] = useState(false);
    const [showCopiedMessage, setShowCopiedMessage] = useState(false);
    const [copyText, setCopyText] = useState("");

    function copyMessageText() {
        // Copy the text to clipboard
        navigator.clipboard.writeText(message);

        // Show the copied text for 3 seconds
        setShowCopiedMessage(true);
        setTimeout(() => {
            setShowCopiedMessage(false);
        }, 3000);
    }

    function getMarkdownText(children) {

        navigator.clipboard.writeText(children[0]);
        setCopyText(children[0])

        // Show the copied text for 2 seconds
        setShowCopiedText(true);
        setTimeout(() => {
            setShowCopiedText(false);
        }, 3000);

    }

    return (

        <div className={`chat-message-container-${theme.theme} ${aiOrUserMessage}-chat-message-container-${theme.theme}`}>
            <div className="chat-message-header-container">
                <p className="chat-message-header-text">
                    {aiOrUserMessage === "ai" ? "AI" : "USER"}
                </p>
                <div onClick={() => copyMessageText()}>
                    {showCopiedMessage && <p className={`medium-font-${theme.theme}`}>copied</p>}
                    {(!showCopiedMessage && aiOrUserMessage === "ai") &&
                        <IconContext.Provider
                            value={{ className: `copy-text-icon-${theme.theme}` }}>
                            <div>
                                <BiCopy />
                            </div>
                        </IconContext.Provider>
                    }
                </div>
            </div>

            <div className="chat-message-content-container">

                <ReactMarkdown
                    children={message}
                    className={`chat-message-text-${theme.theme} markdown`}
                    rehypePlugins={[rehypeRaw]}
                    components={{
                        code({ node, inline, className, children, ...props }) {
                            const match = /language-(\w+)/.exec(className || '')
                            return !inline && match ? (
                                <div>

                                    <div className={`copy-markdown-icon`}
                                        onClick={() => getMarkdownText(children)}>

                                        {(showCopiedText && copyText === children[0]) ?
                                            <p className="copied-text">copied</p>
                                            :
                                            <IconContext.Provider
                                                value={{
                                                    size: "20px",
                                                    color: "#e8e8e8",
                                                }}>
                                                <div>
                                                    <BiCopy />
                                                </div>
                                            </IconContext.Provider>
                                        }
                                    </div>
                                    <SyntaxHighlighter
                                        children={String(children).replace(/\n$/, '')}
                                        language={match[1]}
                                        style={lucario}
                                        customStyle={{ fontSize: "14px" }}
                                        PreTag="div"
                                        {...props}
                                    />
                                </div>
                            ) : (
                                <code className={className} {...props}>
                                    {children}
                                </code>
                            )
                        }
                    }}
                />

                {sources.length > 0 &&
                    <div className="sources-container">
                        <div className="sources-col">
                            <p className={`semi-bold-font-${theme.theme}`} style={{ fontSize: "16px" }}>
                                Sources
                            </p>
                        </div>
                        <div className="sources-col">
                            {sources.map((source, index) =>
                                <div key={index} className="source-row"
                                    onClick={() => onClick(chatMessage["searchQueries"], chatMessage["searchResults"], sources, source)}>
                                    <p className={`source-text-${theme.theme}`}>{source["title"]}</p>
                                </div>
                            )}
                        </div>
                    </div>
                }

                {webSources !== undefined && webSources.length > 0 &&
                    <div className="sources-container">
                        <div className="sources-col">
                            <p className={`semi-bold-font-${theme.theme}`} style={{ fontSize: "16px" }}>
                                Web Sources
                            </p>
                        </div>
                        <div className="sources-col">
                            {webSources.map((source, index) =>
                                <div key={index} className="source-row"
                                    onClick={() => onClick(chatMessage["webSearchQueries"], chatMessage["webSearchResults"], webSources, source)}>
                                    <p className={`source-text-${theme.theme}`}>{source["title"]}</p>
                                </div>
                            )}
                        </div>
                    </div>
                }

            </div>

        </div>
    )

}


const ChatThread = ({ title, onClick, onDeleteClick, onEditClick, disableClick, isCurrentThread = false }) => {

    const [{ theme }, _] = useContext(ThemeContext);

    const helpBoxRef = useRef();
    const ellipsisRef = useRef();

    const [showEditDeleteBox, setShowEditDeleteBox] = useState(false);

    useEffect(() => {
        function handleClickOutside(event) {
            if (helpBoxRef.current && (!helpBoxRef.current.contains(event.target) && !ellipsisRef.current.contains(event.target))) {
                // Hide the help box when the user clicks outside of it, as long as it isn't the ellipsis
                // The box is already hidden when the ellipsis is clicked
                setShowEditDeleteBox(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [helpBoxRef]);

    return (
        <div className={`chat-thread-container-${theme.theme}${(isCurrentThread) ? ` chat-thread-container-selected-${theme.theme}` : ""}`}>
            <div className={"chat-thread-title-col" + (disableClick ? " chat-thread-title-col-disabled" : "")}
                onClick={(disableClick ? null : onClick)}>
                <p className={`semi-bold-font-${theme.theme} chat-thread-title-text`}>{title}</p>
            </div>

            {/*<div className={"delete-chat-thread-col" + (disableClick ? " delete-chat-thread-col-disabled" : "")}>
                <IconContext.Provider value={{ className: `delete-chat-thread-icon-${theme.theme}` }}>
                    <div className={`edit-chat-thread-ellipsis-${theme.theme}`} ref={ellipsisRef}
                        onClick={() => setShowEditDeleteBox(!showEditDeleteBox)}>
                        <IoEllipsisVerticalSharp />
                    </div>
                </IconContext.Provider>

                {showEditDeleteBox &&
                    <div className={`edit-delete-chat-container-${theme.theme}`} ref={helpBoxRef}>
                        <div className="edit-delete-row edit-row" onClick={() => onEditClick()} style={{display: "none"}}>
                            <IconContext.Provider
                                value={{ className: `edit-chat-thread-icon-${theme.theme}` }}>
                                <FaPen />
                            </IconContext.Provider>
                            <p className={`edit-chat-thread-text-${theme.theme}`}>
                                Rename
                            </p>
                        </div>
                        <div className="edit-delete-row delete-row" onClick={() => onDeleteClick()}>
                            <IconContext.Provider
                                value={{ className: "delete-chat-thread-icon" }}>
                                <FaTrash />
                            </IconContext.Provider>
                            <p className="delete-chat-thread-text">
                                Delete chat
                            </p>
                        </div>
                    </div>
                }

            </div>*/}

        </div>
    )

}



export const ChatThreadEditModal = ({ currentTitle, onClick, onClose }) => {

    const [title, setTitle] = useState(currentTitle);

    return (
        <CustomModal closeModal={onClose} customModalContainerStyle={{ width: "600px" }} customMainColStyle={{ paddingTop: "0px" }}>
            <div className="chat-thread-edit-container">
                <div>
                    <p className={`header-font`}>Rename chat thread</p>
                </div>
                <CustomTextInput
                    text={title}
                    sendDataToParent={(text) => setTitle(text)}
                />

                <div style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: "0px", marginTop: "20px" }}>
                    <div style={{ display: "flex", flex: "1", marginRight: "5px" }}>
                        <CustomCancelButton buttonText={"Cancel"} onClick={onClose} />
                    </div>
                    <div style={{ display: "flex", flex: "1", marginLeft: "5px" }}>
                        <CustomButton buttonText={"Confirm"} onClick={() => onClick()} />
                    </div>
                </div>

            </div>
        </CustomModal>
    )

}

export default Chat;