import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import AuthNavigator from './AuthNavigator';
import DashboardNavigator from './DashboardNavigator';

import * as authActions from '../store/actions/auth';

const MainNavigator = () => {

    const [isAuthenticated, setIsAuthenticated] = useState(null);
    console.log("isAuthenticated", isAuthenticated)

    const dispatch = useDispatch();

    // Get the api key from redux
    const idTokenFromStorage = sessionStorage.getItem('idToken');
    const idToken = useSelector(state => state.auth.idToken);

    useEffect(() => {
        if (idTokenFromStorage === null && idToken === null) {
            setIsAuthenticated(false);
            //dispatch(authActions.logout((navigate)));
        } else if (idToken === null && idTokenFromStorage !== null) {
            // This means the page was refreshed, and the session is still valid
            setIsAuthenticated(true);
            dispatch(authActions.setIdToken(idTokenFromStorage));
        } else if (idToken !== null) {
            // This means the page was refreshed, and the session is still valid
            setIsAuthenticated(true);
        }
    }, [idTokenFromStorage, idToken])

    if (isAuthenticated === null) {
        return null;
    } else {

        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<AuthNavigator />} />
                    <Route path="/*" element={isAuthenticated ? <DashboardNavigator /> : <AuthNavigator />} />
                </Routes>
            </BrowserRouter>
        )
    }

}

// <Route path="/*" element={isAuthenticated ? <DashboardNavigator /> : <AuthNavigator />} />

export default MainNavigator;