import React, { useEffect, useState } from 'react';

import LoadingSpinner from '../../components/general/LoadingSpinner';

import { useLoginHandler } from '../../logic/auth/auth';

import T2cLogo from '../../assets/t2c-small-logo.png';

import './LoginRedirect.css';

const { REACT_APP_LOGIN_URL } = process.env;

const LoginRedirect = () => {

    // This screen will handle the redirect after the user logs in. This will request 
    // the user's information from the backend and store it in the redux store. The send
    // The user to the playground screen.

    const [didRequestLoginInfo, setDidRequestLoginInfo] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showEmailPasswordHelp, setShowEmailPasswordHelp] = useState(false);

    const { getLoginInfo } = useLoginHandler(setIsLoading);

    // Extract the id token from the string, with the format: url/login-redirect#id_token=...&access_token=...
    // Or it could be http://localhost:3000/login-redirect#access_token=

    // We need to replace the # with ?& so that we can use the URLSearchParams
    // This only replaces the first instance of a #, so we can reliably do this
    const url = window.location.href.replace('#', '?&');

    // Get the url params
    const urlParams = new URLSearchParams(url);
    const idToken = urlParams.get('id_token');
    //const accessToken = urlParams.get('access_token');
    const error = urlParams.get('error');

    function returnToLogin() {
        window.location.href = REACT_APP_LOGIN_URL;
    }

    useEffect(() => {
        // Save the idToken to session storage
        sessionStorage.setItem('idToken', idToken);
        // Get login info handles all of the logic, redux, and redirects
        if (error != null && error.includes("invalid_request")) {
            // This means the user has previously logged in with email and password
            setShowEmailPasswordHelp(true)
            setIsLoading(false)
        }

        if (!didRequestLoginInfo && idToken != null) {
            getLoginInfo(idToken);
            setDidRequestLoginInfo(true)
        }

    }, [idToken])

    return (
        <div className="login-redirect-container">
            <div className="login-redirect-header-container">
                <img alt="Type 2 Consulting Logo" id="login-page-logo" src={T2cLogo} />
            </div>

            {isLoading && <LoadingSpinner />}
            
            {showEmailPasswordHelp &&
                <div>
                    <p className={"email-password-login-help-text"}>
                        This email address is already associated with an account. Please login with your email and password
                    </p>
                    <p className={"return-to-login-page"} onClick={() => returnToLogin()}>
                        Return to the login page
                    </p>
                </div>
            }
        </div>
    )

}

// {isLoading && <LoadingSpinner />}

export default LoginRedirect;