import {
    SET_ID_TOKEN,
    LOGOUT
} from '../actions/auth';

const initialState = {
    idToken: null,
}

export default (state = initialState, action) => {

    switch (action.type) {
        case SET_ID_TOKEN:
            return {
                ...state,
                idToken: action.idToken,
            };
        case LOGOUT:
            return {
                ...initialState,
            }
        default:
            return state;
    }

}