import React, { useContext } from 'react';

import CustomModal from './CustomModal';
import { CustomDeleteButton, CustomCancelButton } from './CustomButton';
import LoadingSpinner from './LoadingSpinner';
import { ThemeContext } from '../../context/theme';

import './DeleteConfirmationModal.css';

const DeleteConfirmationModal = (props) => {

    const [{ theme }, _ ] = useContext(ThemeContext);

    return (
        <CustomModal
            closeModal={() => props.closeModal()}
            customModalContainerStyle={{ width: "500px", borderRadius: "10px", "paddingTop": "10px" }}>

            {props.isLoading && <LoadingSpinner />}

            <div className="delete-confirmation-modal-main-col">

                <p className={`header-font-${theme.theme}`}>{props.title}</p>

                <div className="delete-confirmation-container">
                    {props.confirmationText !== undefined &&
                        <p className={`regular-font-${theme.theme}`}>{props.confirmationText}</p>
                    }
                    <CustomDeleteButton
                        buttonText={"Delete"}
                        customStyle={{marginBottom: "10px", marginTop: "20px"}}
                        onClick={() => props.isLoading ? console.log("") : props.delete()} />
                    <CustomCancelButton
                        buttonText={"Cancel"}
                        onClick={() => props.isLoading ? console.log("") : (props.onCancel === undefined ? props.closeModal() : props.onCancel())}
                    />
                    
                </div>

            </div>

        </CustomModal>
    )

}

export default DeleteConfirmationModal;
