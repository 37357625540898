import React from 'react';

import DashboardWrapper from '../../components/dashboard/DashboardWrapper';
import Chat from '../../components/chat/Chat';

const BrandMarketing = () => {

    return (
        <DashboardWrapper
            screen={"brand-marketing"}
            customMainContainerStyle={{ paddingTop: "0px", paddingLeft: "0px", paddingRight: "0px", maxWidth: "100%" }}>

            <Chat page={"brand-marketing"} />

        </DashboardWrapper>
    )

}

export default BrandMarketing;