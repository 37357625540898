export const SET_ID_TOKEN = 'SET_ID_TOKEN';
export const LOGOUT = 'LOGOUT';

export function setIdToken(idToken) {
    return dispatch => {
        dispatch({ type: SET_ID_TOKEN, idToken: idToken });
    }
}

export function logout(navigate) {
    console.log("logging out")
    sessionStorage.clear();
    return dispatch => {
        dispatch({ type: LOGOUT });
        navigate('/')
    }
}

let timer;

export const clearLogoutTimer = () => {
    if (timer) {
        console.log("clearing logout timer")
        clearTimeout(timer);
    }
};

export function setAutoLogoutTimer(expirationTime, navigate) {
    clearLogoutTimer();

    return dispatch => {
        timer = setTimeout(() => {
            dispatch(logout(navigate))
            alert("For security purposes, your session has ended")
            navigate('/')

        }, expirationTime);

    };
};