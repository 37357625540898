import React from 'react';

import { IconContext } from 'react-icons';
import { MdAccountCircle } from "react-icons/md";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaChartLine, FaMoneyBillWave, FaBoxOpen } from "react-icons/fa";
import { GiBrain } from "react-icons/gi";
import { IoPricetag } from "react-icons/io5";
import { BsPersonHeart } from "react-icons/bs";

import { CustomButton } from "../../components/general/CustomButton";

import T2cLogo from '../../assets/t2c-small-logo.png';
import T2cLogoLarge from '../../assets/T2_clr_symbol.jpg';


import "./Home.css";

const { REACT_APP_SIGNUP_URL } = process.env;

const Home = () => {

    const icons = [
        { title: "Strategic", icon: <GiBrain /> },
        { title: "Financial", icon: <FaMoneyBillWave /> },
        { title: "Product", icon: <FaBoxOpen /> },
        { title: "Account based", icon: <MdAccountCircle /> },
        { title: "Customer cohort", icon: <FaPeopleGroup /> },
        { title: "Performance", icon: <FaChartLine /> },
        { title: "Brand", icon: <IoPricetag /> },
        { title: "Employee-led", icon: <BsPersonHeart /> },
    ]

    return (
        <div className="homepage-section">

            <div className="homepage-header">
                <div>
                    <img src={T2cLogo} id="header-logo" />
                </div>

                <div id="homepage-login-container">
                    <p id="homepage-login-text" onClick={() => window.location.href = REACT_APP_SIGNUP_URL}>
                        Log in
                    </p>
                    <CustomButton
                        buttonText={"Create Account"}
                        onClick={() => window.location.href = REACT_APP_SIGNUP_URL}
                    />
                </div>
            </div>

            <div className="homepage-container">
                <p className="homepage-main-text">WELCOME</p>
                <p className="homepage-sub-text">If you are here, it is because you heard Jonathan Knowles describe how the study of a large volume of articles about marketing accountability revealed that there are <b>2 Whys and 6 Hows of Marketing.</b></p>
                <p className="homepage-main-text" style={{ display: "none" }}>2 Whys 6 Hows of Marketing</p>
                <div>
                    <p className="homepage-sub-text" style={{ display: "none" }}>
                        Marketing is the study of context.
                    </p>
                    <p className="homepage-sub-text">
                        This website allows you to interact with a chatbot that specializes in each of these 8 perspectives on Marketing (each chat bot uses Retrieval Augmented Generation [RAG] to access a sub library of the articles associated with each perspective).
                    </p>
                    <div className="homepage-button-container">
                        {/*<CustomButton buttonText={"Get Started"} onClick={() => window.location.href = REACT_APP_SIGNUP_URL} />*/}
                        <img src={T2cLogoLarge} id="homepage-logo" />
                    </div>
                    <p className="homepage-sub-text">
                        Access is free (for now) but registration is required so that any queries you make are saved for a future visit.
                    </p>
                    <p className="homepage-sub-text">
                        Have fun exploring each of these 8 perspectives on how marketing should be defined, managed, and measured.
                    </p>
                    <p className="homepage-sub-text">
                        Please provide feedback to j.knowles@type2consulting.com
                    </p>
                </div>


            </div>

            <div className="homepage-footer">

                {icons.map((icon, index) =>
                    <IconContext.Provider
                        value={{ className: "homepage-icon" }}>
                        <div key={index}>
                            {icon.icon}
                        </div>
                    </IconContext.Provider>
                )}

            </div>

        </div>
    )

}

export default Home;