import React, { useState, useEffect, useRef, useContext } from 'react';

import { IconContext } from 'react-icons';
import { RiSendPlaneFill } from 'react-icons/ri';

import { ThemeContext } from '../../context/theme';

import './ChatInput.css';

const ChatInput = ({ sendMessage, sendMessageDisabled, sendDataToParent, placeholderMessage }) => {
    // This will be the input field for the user to type in
    // This will also contain a button for the user to send the message

    const [{ theme }, _ ] = useContext(ThemeContext);

    const [message, setMessage] = useState("");
    const textAreaRef = useRef(null);
    //const [messageLength, setMessageLength] = useState(0);

    const handleChange = (evt) => {
        const val = evt.target?.value;
        setMessage(val);
    };

    const useAutosizeTextArea = (textAreaRef, message) => {
        useEffect(() => {
            if (textAreaRef) {
                // We need to reset the height momentarily to get the correct scrollHeight for the textarea
                textAreaRef.style.height = "0px";
                const scrollHeight = textAreaRef.scrollHeight;

                // We then set the height directly, outside of the render loop
                // Trying to set this with state or a ref will product an incorrect value.
                textAreaRef.style.height = Math.min(scrollHeight, 192) + "px";
            }
        }, [textAreaRef, message]);
    };

    useAutosizeTextArea(textAreaRef.current, message);

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter' && !event.shiftKey && message.length > 0 && !sendMessageDisabled) {
                event.preventDefault();
                sendMessage(message);
                setMessage("");
            } else if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
            }
        };

        // If the send message button is disabled, we don't want to add the event listener
        // Having the event listener prevents a user from being able to use the enter key to create a new line
        // when a modal is open on the screen
        if (!sendMessageDisabled) {
            document.addEventListener('keydown', keyDownHandler);
        } else {
            document.removeEventListener('keydown', keyDownHandler);
        }
        
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [message, sendMessageDisabled]);

    useEffect(() => {
        // Get the message length
        sendDataToParent(message)
    }, [message])

    return (
        <div className={`chat-input-container`}>
            <div className={`chat-input-row-${theme.theme}`}>
                <textarea
                    className={`chat-input-${theme.theme}`}
                    onChange={handleChange}
                    placeholder={placeholderMessage == undefined ? "Send a message" : placeholderMessage}
                    ref={textAreaRef}
                    rows={1}
                    value={message}
                />
                <div className="send-icon-container" onClick={() => {message.length > 0 ? sendMessage(message) : console.log(""); setMessage("") }}>
                    <IconContext.Provider
                        value={{className: `send-icon-${theme.theme}`}}>
                        <div>
                            <RiSendPlaneFill />
                        </div>
                    </IconContext.Provider>
                </div>
            </div>
            
        </div>
    )

}

export default ChatInput;
