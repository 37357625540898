import { createContext, useState } from 'react';

const themes = {
    light: {
        theme: "light",
        backgroundColor: "var(--off-white)",
        mainFontColor: "var(--off-black)",
        sidenavBackgroundColor: "#FFFFFF",
        headerBorder: "1px solid #e0e0e0",
    },
    dark: {
        theme: "dark",
        backgroundColor: "var(--dark-theme-background-color)",
        sidenavBackgroundColor: "var(--dark-theme-off-black)",
        mainFontColor: "var(--divider-line-gray)",
        headerBorder: "none",
    }
}

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {

    // Get the saved state from local storage
    const isDarkFromStorage = localStorage.getItem('isDark');

    const [isDark, setIsDark] = useState(isDarkFromStorage === 'true' ? true : false);
    const theme = isDark ? themes.dark : themes.light;
    function toggleTheme() {
        setIsDark(!isDark);
        // Save this to local storage
        localStorage.setItem('isDark', !isDark);
        // Update the background color of the body
        document.body.style.backgroundColor = isDark ? themes.light.backgroundColor : themes.dark.backgroundColor;
    }

    return (
        <ThemeContext.Provider value={[{theme, isDark}, toggleTheme]}>
            {children}
        </ThemeContext.Provider>
    )

}