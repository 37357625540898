import React, { useEffect } from 'react';
import CustomModal from '../../components/general/CustomModal';

import "./QueriesAndResultsModal.css";

const QueriesAndResultsModal = ({ queries, citedResults, onClose, theme, selectedSource }) => {

    useEffect(() => {
        if (selectedSource !== null) {
            // Find the index of the selected source in the list of sources
            const sourceIndex = citedResults.findIndex((source) => source["title"] === selectedSource["title"]);
            // Scroll to that id
            console.log("sourceIndex", sourceIndex)
            const element = document.getElementById(`chat-result-${sourceIndex}`);
            element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [selectedSource])

    return (
        <CustomModal closeModal={onClose}
            customMainColStyle={{ paddingTop: "10px", overflowY: "scroll" }}
            customModalContainerStyle={{ maxHeight: "90vh", width: "80vw", maxWidth: "80vw", paddingBottom: "40px" }}>
            <div style={{ width: "100%" }}>

                <div>
                    {queries !== null && queries !== undefined &&
                        <p className={`header-font-${theme.theme}`} style={{ marginBottom: "10px" }}>
                            Search queries
                        </p>
                    }
                    {queries !== null && queries !== undefined &&
                        <QuerySearchCard searchQueries={queries} theme={theme} />
                    }
                </div>
                
                <div style={{ marginTop: "35px", paddingBottom: "20px" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <p className={`chat-result-type-text-${theme.theme}-selected`}
                            style={{ marginBottom: "15px" }}>
                            {`Cited results (${(citedResults == undefined ? "0" : citedResults.length)})`}
                        </p>
                    </div>
                    {citedResults != undefined && citedResults.map((result, index) =>
                        <div key={index} id={`chat-result-${index.toString()}`}>
                            <QueryResultsCard
                                result={result}
                                index={index} theme={theme} />
                        </div>
                    )}
                </div>

            </div>

        </CustomModal>
    )

}



export const QuerySearchCard = ({ searchQueries, theme }) => {

    return (
        <div className="query-search-card-section">
            {searchQueries.map((queryData, index) =>
                <div className={`query-search-card-container-${theme.theme}`} key={index}>
                    <div>
                        <p className={`regular-font-${theme.theme}`}>{queryData["query"]} {"->"} </p>
                    </div>
                    <div>
                        <p className={`semi-bold-font-${theme.theme}`} style={{ marginLeft: "3px" }}>
                            {queryData["knowledge_base"] === undefined ? "" : queryData["knowledge_base"]["title"]}
                        </p>
                    </div>
                </div>
            )}
        </div>
    )

}


export const QueryResultsCard = ({ result, theme }) => {

    console.log("result", result)

    return (
        <div className={`chat-result-card-container-${theme.theme}`}>
            <div className="query-result-card-header-row">
                <p className={`medium-font-${theme.theme}`} style={{ fontSize: "16px", color: "var(--medium-gray)" }}>From - </p>
                <div className={`query-result-title-container-${theme.theme}`}>
                    <p className={`chat-result-title-text-${theme.theme} ${result.url !== undefined ? "query-result-link" : ""}`}
                        onClick={() => result.url === undefined ? console.log("") : window.open(result.url, "_blank")}>
                        {result.document === undefined ? (result.url === undefined ? result.title : result.url) : result.document.title}
                    </p>
                </div>
            </div>
            <div>
                <p className={`regular-font-${theme.theme}`} style={{ whiteSpace: "pre-wrap" }}>
                    {result.content}
                </p>
            </div>
        </div>
    )

}

export default QueriesAndResultsModal;