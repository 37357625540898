const {
    REACT_APP_SERVICE_BASE_URL_PRIVATE,
    REACT_APP_SERVICE_BASE_URL,
    REACT_APP_API_KEY,
    REACT_APP_API_SECRET
} = process.env;

const authToken = btoa(unescape(encodeURIComponent(`${REACT_APP_API_KEY}:${REACT_APP_API_SECRET}`)));

export async function getUserAccountID(idToken) {
    const response = await fetch(`${REACT_APP_SERVICE_BASE_URL_PRIVATE}/accounts/decode_jwt`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": idToken
        },
    });
    const data = await response.json();
    return data;
}


export async function fetchUsage(startTime, endTime) {
    let url = `${REACT_APP_SERVICE_BASE_URL}/usage/billable_events?start_time=${startTime}&end_time=${endTime}`;
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": authToken
        },
    });
    const data = await response.json();
    return data;
}